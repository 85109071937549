import React, { useState, useEffect } from 'react';
import {
  Box, Button, Typography, TextField, CircularProgress, Dialog, DialogTitle, DialogContent, DialogActions,
  FormControl, Select, MenuItem, InputLabel, Chip, Switch, FormControlLabel, Snackbar, Alert
} from '@mui/material';
import { createPolicy, updatePolicy, getDatasources } from '../api/askTableApi';
import { useTheme } from '@mui/material/styles';

const PolicyDialog = ({ open, onClose, editPolicy, onSave }) => {
  const theme = useTheme();

  const [name, setName] = useState('');
  const [permission, setPermission] = useState('allow');
  const [datasourceIds, setDatasourceIds] = useState([]);
  const [regexPatterns, setRegexPatterns] = useState({ schemas_regex_pattern: '', tables_regex_pattern: '', fields_regex_pattern: '' });
  const [rowsFilters, setRowsFilters] = useState({});
  const [loading, setLoading] = useState(false);
  const [datasources, setDatasources] = useState([]);
  const [allDatasources, setAllDatasources] = useState({});
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [error, setError] = useState('');
  const [openTips, setOpenTips] = useState(false);

  useEffect(() => {
    if (editPolicy) {
      setName(editPolicy.name);
      setPermission(editPolicy.permission);
      setDatasourceIds(editPolicy.dataset_config.datasource_ids.split(','));
      setRegexPatterns(editPolicy.dataset_config.regex_patterns);
      setRowsFilters(editPolicy.dataset_config.rows_filters || {});
    } else {
      resetForm();
    }
  }, [editPolicy]);
  
  useEffect(() => {
    const fetchDatasources = async () => {
      try {
        setDatasources([]); // 清空旧的数据源
        setAllDatasources({}); // 清空旧的allDatasources
        
        const data = await getDatasources(6, page, 'desc');
        setDatasources(data.data);
        setTotalPages(data.total_page);
  
        const allData = {};
        data.data.forEach((ds) => {
          allData[ds.id] = ds;
        });
        setAllDatasources(allData);
      } catch (error) {
        console.error('Failed to fetch datasources:', error);
      }
    };
  
    fetchDatasources();
  }, [page]);
  

  useEffect(() => {
    const fetchDatasources = async () => {
      try {
        const data = await getDatasources(6, page, 'desc');
        setDatasources(data.data);
        setTotalPages(data.total_page);

        const allData = { ...allDatasources };
        data.data.forEach((ds) => {
          allData[ds.id] = ds;
        });
        setAllDatasources(allData);
      } catch (error) {
        console.error('Failed to fetch datasources:', error);
      }
    };

    fetchDatasources();
  }, [page]);

  const resetForm = () => {
    setName('');
    setPermission('allow');
    setDatasourceIds([]);
    setRegexPatterns({ schemas_regex_pattern: '', tables_regex_pattern: '', fields_regex_pattern: '' });
    setRowsFilters({});
  };

  const handleSave = async () => {
    setLoading(true);
    const datasetConfig = {
      datasource_ids: datasourceIds.join(','),
      regex_patterns: regexPatterns,
      rows_filters: permission === 'allow' ? rowsFilters : {}
    };
    try {
      if (editPolicy) {
        await updatePolicy(editPolicy.id, { name, permission, dataset_config: datasetConfig });
      } else {
        await createPolicy({ name, permission, dataset_config: datasetConfig });
      }
      onSave();
      onClose();
      resetForm();
    } catch (error) {
      console.error('Failed to save policy:', error);
      setError(error.message);
      setOpenTips(true);
    } finally {
      setLoading(false);
    }
  };

  const handleDatasourceChange = (event) => {
    setDatasourceIds(event.target.value);
  };

  const handleRegexPatternChange = (field, value) => {
    setRegexPatterns((prev) => ({ ...prev, [field]: value }));
  };

  const handleRowFilterChange = (datasourceId, index, value) => {
    setRowsFilters((prev) => ({
      ...prev,
      [datasourceId]: prev[datasourceId].map((filter, i) => (i === index ? value : filter))
    }));
  };

  const addRowFilter = (datasourceId) => {
    setRowsFilters((prev) => ({
      ...prev,
      [datasourceId]: [...(prev[datasourceId] || []), '']
    }));
  };
 
  const removeDatasource = (id) => {
    setDatasourceIds((prev) => prev.filter((dsId) => dsId !== id));
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenTips(false);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle variant="h5" sx={{ mt: 1.5 , fontWeight: 'bold' }}>{editPolicy ? '编辑策略' : '创建策略'}</DialogTitle>
      <DialogContent>
        <TextField
          label="名称"
          fullWidth
          margin="normal"
          value={name}
          onChange={(e) => setName(e.target.value)}
          helperText="由小写字母、数字和下划线组成，不超过64个字符"
          sx={{ fontSize: '1rem' }}
        />
        <FormControlLabel
          control={<Switch checked={permission === 'allow'} onChange={(e) => setPermission(e.target.checked ? 'allow' : 'deny')} />}
          label={permission === 'allow' ? '允许访问以下数据' : '不允许访问以下数据'}
          sx={{ marginBottom: '1rem', fontSize: '1rem' }}
        />
        <FormControl fullWidth>
          <InputLabel sx={{ fontSize: '1rem' }}>数据源</InputLabel>
          <Select
            multiple
            value={datasourceIds}
            onChange={handleDatasourceChange}
            renderValue={(selected) => (
              <Box display="flex" flexWrap="wrap" gap={0.5}>
                {selected.map((id) => {
                  const ds = allDatasources[id];
                  return (
                    <Chip
                      key={id}
                      label={ds ? ds.name : name}
                      sx={{ color: theme.palette.text.secondary, fontSize: '0.875rem' }}
                    />
                  );
                })}
              </Box>
            )}
            MenuProps={{
              PaperProps: {
                sx: {
                  width: 'auto',
                  minWidth: '100%',
                }
              },
              MenuListProps: {
                sx: {
                  padding: 0
                }
              }
            }}
            sx={{ fontSize: '0.875rem' }} 
          >
            {datasources.map((ds) => (
              <MenuItem key={ds.id} value={ds.id}>
                <Box display="flex" flexDirection="column">
                  <Typography variant="body1" >{ds.name ? ds.name : '未命名数据源'}</Typography>
                  <Typography variant="caption" color="primary">{ds.id}</Typography>
                </Box>
              </MenuItem>
            ))}
            <Box display="flex" justifyContent="space-between" mt={2} mb={2}>
              <Button onClick={() => setPage((prev) => Math.max(prev - 1, 1))} disabled={page === 1}>上一页</Button>
              <Button onClick={() => setPage((prev) => Math.min(prev + 1, totalPages))} disabled={page === totalPages}>下一页</Button>
            </Box>
          </Select>
        </FormControl>
        <Box mt={2}>
          <Typography variant="h6" sx={{ fontSize: '1.25rem', fontWeight: 'bold' }}>选择库表</Typography>
          <TextField
            label="用于匹配数据库名的正则表达式"
            fullWidth
            margin="dense"
            value={regexPatterns.schemas_regex_pattern}
            onChange={(e) => handleRegexPatternChange('schemas_regex_pattern', e.target.value)}
            helperText="为空则表示全部。示例: ^public_.*$"
           
          />
          <TextField
            label="用于匹配表名的正则表达式"
            fullWidth
            margin="dense"
            value={regexPatterns.tables_regex_pattern}
            onChange={(e) => handleRegexPatternChange('tables_regex_pattern', e.target.value)}
            helperText="为空则表示全部。示例: ^(user|shop)_.*$"
            
          />
          <TextField
            label="用于匹配字段名的正则表达式"
            fullWidth
            margin="dense"
            value={regexPatterns.fields_regex_pattern}
            onChange={(e) => handleRegexPatternChange('fields_regex_pattern', e.target.value)}
            helperText="为空则表示全部。示例: .*password.*|.*pwd.*"
          />
        </Box>
        {permission === 'allow' && (
          <>
            <Typography variant="h6" gutterBottom sx={{ fontSize: '1.25rem', fontWeight: 'bold', marginTop: '0.5rem' }}>行过滤器</Typography>
            {datasourceIds.map((dsId) => {
              const ds = allDatasources[dsId];
              return (
                <Box key={dsId} mb={2}>
                  <Typography>
                     数据源：{ds ? ds.name : ''} <Typography component="span" variant="caption" >({dsId})</Typography>
                  </Typography>
                  {(rowsFilters[dsId] || []).map((filter, index) => (
                    <TextField
                      key={index}
                      label={`过滤条件 ${index + 1}`}
                      fullWidth
                      margin="normal"
                      value={filter}
                      onChange={(e) => handleRowFilterChange(dsId, index, e.target.value)}
                      helperText="示例: public.*.id = {{user_id}}"
                      sx={{ fontSize: '1rem' }}
                    />
                  ))}
                  <Button variant="outlined" onClick={() => addRowFilter(dsId)} sx={{ fontSize: '0.875rem', marginTop: '0.5rem' }}>添加过滤条件</Button>
                </Box>
              );
            })}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} sx={{ fontSize: '1rem' }}>取消</Button>
        <Button onClick={handleSave} color="primary" disabled={loading} sx={{ fontSize: '1rem' }}>
          {loading ? <CircularProgress size={24} /> : '保存'}
        </Button>
      </DialogActions>
      <Snackbar
        open={openTips}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>
    </Dialog>
  );
};

export default PolicyDialog;
