import React, { useState, useEffect, useCallback } from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { getDatasource, getBot } from '../api/askTableApi';
import { styled } from '@mui/material/styles';

const CustomPopover = styled(Popover)(({ theme }) => ({
  '& .MuiPaper-root': {
    overflow: 'visible',
    backgroundColor: '#fff',
    position: 'relative',
    marginTop: '-10px',
    width: '365px',
    boxShadow: 'none', // 移除阴影
    transition: 'none', // 取消过渡效果
    backgroundColor: '#E2F6EE',
    '&::before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: -10,
      left: 'calc(50% - 10px)',
      width: 0,
      height: 0,
      borderLeft: '10px solid transparent',
      borderRight: '10px solid transparent',
      borderBottom: '10px solid #E2F6EE',
    },
  },
}));

const HoverPopover = ({ anchorEl, handleClose, id, type }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isFetched, setIsFetched] = useState(false); // 用于追踪是否已经获取过数据

  const fetchData = useCallback(async () => {
    if (!id || !type) return;
    setLoading(true);
    setData(null);

    try {
      let result;
      if (type === 'datasource') {
        result = await getDatasource(id);
      } else if (type === 'bot') {
        result = await getBot(id);
      }
      setData(result);
      setIsFetched(true); // 标记为已获取数据
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  }, [id, type]);

  useEffect(() => {
    if (anchorEl && !isFetched) {
      fetchData();
    }
  }, [anchorEl, isFetched, fetchData]);

  const handleMouseLeave = useCallback(() => {
    handleClose();
    setIsFetched(false); // 关闭后重置标记
  }, [handleClose]);

  return (
    <>
      {anchorEl && data && !loading && (
        <CustomPopover
          id="simple-popover"
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          slotProps={{
            paper: {
              onMouseLeave: handleMouseLeave,
            }
          }}
        >
          <div style={{ padding: '20px' }}>
            {type === 'datasource' && (
              <>
                <Typography variant="h6" sx={{ borderBottom: '1px solid black', marginBottom: '16px', fontWeight: '700' }}>数据源详情</Typography>
                <Typography>名字&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;{data.name}</Typography>
                <Typography>状态&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;{data.meta_status}</Typography>
                <Typography>库/表/列&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;1/20/2000</Typography>
                <Typography>数据源类型&nbsp;:&nbsp;{data.engine}</Typography>
                <Typography>创建时间&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;{Intl.DateTimeFormat('default', {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                  hour: '2-digit',
                  minute: '2-digit',
                  second: '2-digit',
                  hour12: false, // 24小时制
                }).format(new Date(data.created_at))|| '无'}</Typography>
              </>
            )}
            {type === 'bot' && (
              <>
                <Typography variant="h6" sx={{ borderBottom: '1px solid black', marginBottom: '16px', fontWeight: '700' }}>机器人详情</Typography>
                <Typography>名字&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;{data.name}</Typography>
                <Typography>botId&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;{data.id}</Typography>
                <Typography>数据源&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;{data.datasource_ids}</Typography>
                <Typography>创建时间&nbsp;&nbsp;:&nbsp;{Intl.DateTimeFormat('default', {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                  hour: '2-digit',
                  minute: '2-digit',
                  second: '2-digit',
                  hour12: false, // 24小时制
                }).format(new Date(data.created_at))|| '无'}
                  </Typography>
              </>
            )}
          </div>
        </CustomPopover>
      )}
    </>
  );
};

export default HoverPopover;
