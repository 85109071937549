import React, { useState, useEffect } from 'react';
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Dialog, DialogTitle, DialogContent, DialogActions, IconButton, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { createProjectMember, deleteProjectMember } from '../../api/projectApi';
import { useProjectContext } from '../../components/Context';

const MemberManager = () => {
  const [members, setMembers] = useState([]);
  const [memberOpen, setMemberOpen] = useState(false);
  const [memberId, setMemberId] = useState('');

  const {projectData, setProjectData} = useProjectContext();

  useEffect(() => {
      setMembers(projectData.users);
  }, [projectData]);

  const createMember = async () => {
      const newmember = await createProjectMember(projectData.project_id, memberId);
      setProjectData({...projectData, users: [...projectData.users, newmember.member]});
      setMemberOpen(false);
  };

  const removeMember = async (userId) => {
      await deleteProjectMember(projectData.project_id, userId);
      setMembers(prev => prev.filter(m => m.user_id !== userId));
  };

  return (
    <Box>
      <Typography variant="h5" fontWeight="bold">项目成员</Typography>
      <Typography>一个项目最多允许添加 10 个成员（含自己）。</Typography>
      <TableContainer component={Paper} sx={{ marginTop: '10px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>昵称</TableCell>
              <TableCell>加入时间</TableCell>
              <TableCell>用户类型</TableCell>
              <TableCell>成员操作</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {members?.map((member) => (
              <TableRow key={member.user_id}>
                <TableCell>{member.user_name}</TableCell>
                <TableCell>{member.join_at}</TableCell>
                <TableCell>{member.user_role === "owner" ? "拥有者" : "成员"}</TableCell>
                <TableCell>
                  <Button onClick={() => removeMember(member.user_id)} variant="outlined" color="secondary">移除成员</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Button variant="outlined" color="primary" sx={{ marginTop: '10px' }} onClick={() => setMemberOpen(true)}>
        + 添加新成员
      </Button>
      <Dialog open={memberOpen} onClose={() => setMemberOpen(false)} PaperProps={{style: {minWidth: '300px',maxWidth: '500px'}}} >
        <DialogTitle variant="h5" fontWeight="bold" sx={{ mt: 2}}>添加新成员<Typography sx={{ mt: 2}}>请联系对方，获取对方的用户 ID（在 AskTable 注册后个人中心可查看）</Typography></DialogTitle>
        
        <IconButton
          onClick={() => setMemberOpen(false)}
          sx={{ position: 'absolute', top: 13, right: 13,mt: 2 }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <TextField
            label="用户ID"
            variant="outlined"
            fullWidth
            value={memberId}
            onChange={(e) => setMemberId(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={createMember} variant="outlined" sx={{ mb: 2, mr:2}}>添加</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default MemberManager;