import React, { useState, useEffect } from 'react';
import { Box, Button, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, CircularProgress, Snackbar } from '@mui/material';
import { getExtAPIRoutes, deleteExtAPIRoute } from '../../api/askTableApi';
import ExtAPIRouteDialog from './ExtAPIRouteDialog'; // 引入 ExtAPIRouteDialog 组件
import { Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';

const ExtAPIRouteList = ({ extAPIId }) => {
  const [routes, setRoutes] = useState([]);  // 初始状态为空数组
  const [loading, setLoading] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedRoute, setSelectedRoute] = useState(null); // 控制编辑模式的 Route
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchRoutes = async () => {
      try {
        const response = await getExtAPIRoutes(extAPIId);
        setRoutes(response || []);  // 确保数据为数组
      } catch (error) {
        setError('Failed to fetch routes');
      } finally {
        setLoading(false);
      }
    };

    fetchRoutes();
  }, [extAPIId]);

  const handleDelete = async (routeId) => {
    if (window.confirm('确定要删除这个 route 吗？')) {
      try {
        await deleteExtAPIRoute(extAPIId, routeId);
        setRoutes(routes.filter(route => route.id !== routeId));
      } catch (error) {
        setError('Failed to delete route');
      }
    }
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setSelectedRoute(null);
  };

  const handleEdit = (route) => {
    setSelectedRoute(route);
    setDialogOpen(true);
  };

  const handleCreate = () => {
    setSelectedRoute(null);
    setDialogOpen(true);
  };

  const handleSave = (route) => {
    if (selectedRoute) {
      setRoutes(routes.map(r => r.id === route.id ? route : r));
    } else {
      setRoutes([route, ...routes]);
    }
    handleDialogClose();
  };

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h5" gutterBottom>Routes</Typography>
      <Button variant="contained" color="primary"  sx={{ marginTop: '20px' }} onClick={handleCreate}>创建 Route</Button>
      {loading ? (
        <CircularProgress />
      ) : (
        <TableContainer component={Paper} sx={{ marginTop: 3 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>名称</TableCell>
                <TableCell>HTTP 方法</TableCell>
                <TableCell>路径</TableCell>
                <TableCell>操作</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {routes.length > 0 ? (
                routes.map((route) => (
                  <TableRow key={route.id} hover sx={{ cursor: 'pointer' }}>
                    <TableCell>{route.name}</TableCell>
                    <TableCell>{route.method}</TableCell>
                    <TableCell>{route.path}</TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleEdit(route)}><EditIcon /></IconButton>
                      <IconButton onClick={() => handleDelete(route.id)}><DeleteIcon /></IconButton>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={4}>
                    <Typography variant="body1" align="center">没有可用的 routes</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <ExtAPIRouteDialog
        open={dialogOpen}
        onClose={handleDialogClose}
        onSave={handleSave}
        route={selectedRoute}
        extAPIId={extAPIId}
      />
      {error && <Snackbar open autoHideDuration={6000} onClose={() => setError('')} message={error} />}
    </Box>
  );
};

export default ExtAPIRouteList;
