import React, { useState, useEffect } from 'react';
import {Stack, Box, Typography, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Button,Link } from '@mui/material';
import ReplyIcon from '@mui/icons-material/Reply';
import { useAppContext1, useProjectContext } from '../../components/Context';
import { deleteProject, updateProjectName, getProject } from '../../api/projectApi';
import ProjectNameEditor from './ProjectNameEditor';
import ApiKeyManager from './ApiKeyManager';
import MemberManager from './MemberManager';
import { useNavigate } from 'react-router-dom';

const Setting = () => {
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);

  const {urlProjectId, projectData} = useProjectContext();
  const navigate = useNavigate();

  const handleDeleteClick = () => {
    setDeleteConfirmOpen(true);
  };

  const handleDeleteConfirm = async () => {
      const data = await deleteProject(urlProjectId);
      setDeleteConfirmOpen(false);
      navigate(`/${data.project_id}/data/data-source`);
  };

  const handleDeleteCancel = () => {
    setDeleteConfirmOpen(false);
  };

  return (
    <Box>
      <Box sx={{ paddingTop: '30px !important' }}>
        <Typography variant="h4" sx={{ marginBottom: '20px' }}>
          <IconButton
            sx={{
              marginBottom: '5px',
              marginRight: '5px',
              '&:hover': { color: (theme) => `${theme.palette.primary.main}` },
            }}
            onClick={() => navigate(-1)}
          >
            <ReplyIcon />
          </IconButton>
          设置
        </Typography>

        <Stack spacing={3} sx={{ ml: 2 }}>
      <ProjectNameEditor />
      <ApiKeyManager />
      <MemberManager />
      <Stack>
      <Box sx={{ marginBottom: '40px' }}>
          <Typography variant="h5" fontWeight="bold"  sx={{mb:0.5}}>消费记录</Typography>
          <Box>
            <Typography>您可以通过与 AskTable 提供的 Bot （位于右下侧） 对话，来查询该项目的消费记录。</Typography>
            <Typography>
              确保您获得快速且准确的帮助。详情请参考
              <Link href="https://docs.asktable.com/docs/pricing-and-deployment/private-deployment-options" target="_blank" rel="noopener" sx={{ ml: 1 }}>
                关于 Billing 的帮助文档
              </Link>。
            </Typography>
          </Box>
        </Box>

      </Stack>
    </Stack>
        <Dialog open={deleteConfirmOpen} onClose={handleDeleteCancel}>
          <DialogTitle>确认删除</DialogTitle>
          <DialogContent>
            <Typography variant="body1">
              您确定要删除项目 "{projectData.project_name ? projectData.project_name : urlProjectId}" 吗？此操作不可撤销。
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteCancel} variant="outlined">取消</Button>
            <Button onClick={handleDeleteConfirm} variant="contained" color="error">删除</Button>
          </DialogActions>
        </Dialog>
        <Button onClick={handleDeleteClick} variant="outlined" >
          删除项目
        </Button>

      </Box>
    </Box>
  );
};

export default Setting;