import React, { useState, useEffect } from 'react';
import { getPolicy, getDatasources } from '../api/askTableApi';
import { Box, Typography, CircularProgress, Paper, Divider, Tooltip, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ReactComponent as MysqlIcon } from '../assets/files/mysqlIcon.svg';
import { ReactComponent as TidbIcon } from '../assets/files/tidbIcon.svg';
import { ReactComponent as PostgresqlIcon } from '../assets/files/postgresqlIcon.svg';
import { ReactComponent as OceanBaseIcon } from '../assets/files/oceanbaseIcon.svg';
import { ReactComponent as ExcelIcon } from '../assets/files/excelIcon.svg';
import { ReactComponent as CSVIcon } from '../assets/files/csvIcon.svg';

const PolicyDetail = ({ policyId, refresh }) => {
  const theme = useTheme();
  const [policy, setPolicy] = useState(null);
  const [loading, setLoading] = useState(true);
  const [datasources, setDatasources] = useState([]);

  useEffect(() => {
    const fetchPolicyDetails = async () => {
      try {
        const policyData = await getPolicy(policyId);
        setPolicy(policyData);

        const allDatasources = await getDatasources(50, 1, 'desc');
        const relatedDatasourceIds = policyData.dataset_config.datasource_ids.split(',');
        const relatedDatasources = allDatasources.data.filter(ds =>
          relatedDatasourceIds.includes(ds.id)
        );
        setDatasources(relatedDatasources);
        setLoading(false);
      } catch (error) {
        console.error('Failed to fetch policy details:', error);
      }
    };

    fetchPolicyDetails();
  }, [policyId, refresh]);

  const getStatusLabel = (status) => {
    switch (status) {
      case 'processing':
        return "正在分析元数据";
      case 'failed':
        return "分析元数据失败";
      case 'success':
        return "正常可用";
      case 'unprocessed':
        return "暂未开始";
      default:
        return "未知状态";
    }
  };

  const getEngineIcon = (engine) => {
    switch (engine) {
      case 'mysql':
        return <MysqlIcon />;
      case 'tidb':
        return <TidbIcon />;
      case 'postgresql':
        return <PostgresqlIcon />;
      case 'oceanbase':
        return <OceanBaseIcon />;
      case 'excel':
        return <ExcelIcon />;
      case 'csv':
        return <CSVIcon />;
      default:
        return null;
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Box p={3} sx={{ fontSize: '14px' }}>
      <Typography variant="h6" sx={{ marginBottom: '16px', fontWeight: 'bold' }}>
        策略详情
      </Typography>

      <Divider sx={{ marginBottom: '16px' }} />

      <Box mb={2}>
        {[
          { label: 'ID', value: policy.id },
          { label: '名字', value: policy.name },
          { label: '创建时间', value: new Date(policy.created_at).toLocaleString() },
          { label: '修改时间', value: new Date(policy.modified_at).toLocaleString() },
        ].map((item, index) => (
          <Box display="flex" key={index} mb={1}>
            <Typography sx={{ fontWeight: 'bold', minWidth: '70px' }}>{item.label}</Typography>
            <Typography>:</Typography>
            <Typography sx={{ marginLeft: '8px' }}>{item.value}</Typography>
          </Box>
        ))}
      </Box>

      <Divider sx={{ marginBottom: '16px' }} />

      <Box mb={2}>
        <Typography variant="subtitle1" fontWeight="bold">
          数据源
        </Typography>
        <Grid container spacing={2} mt={1}>
          {datasources.map(ds => (
            <Grid item xs={12} sm={6} md={4} key={ds.id}>
              <Tooltip
                title={
                  <Box>
                    {[
                      { label: '名称', value: ds.name },
                      { label: '状态', value: getStatusLabel(ds.meta_status) },
                      { label: '类型', value: ds.engine },
                      { label: '时间', value: new Date(ds.created_at).toLocaleString() },
                    ].map((item, index) => (
                      <Box display="flex" key={index}>
                        <Typography variant="body2" sx={{ fontWeight: 'bold', minWidth: '30px' }}>
                          {item.label}
                        </Typography>
                        <Typography variant="body2">:</Typography>
                        <Typography variant="body2" sx={{ marginLeft: '8px' }}>
                          {item.value}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                }
              >
                <Paper sx={{ padding: '8px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {getEngineIcon(ds.engine)}
                    <Typography sx={{ marginLeft: '8px', fontWeight: 'bold', fontSize: '14px' }}>
                      {ds.name}
                    </Typography>
                  </Box>
                </Paper>
              </Tooltip>
            </Grid>
          ))}
        </Grid>
      </Box>

      <Divider sx={{ marginBottom: '16px' }} />

      <Box mb={2}>
        <Typography variant="subtitle1" fontWeight="bold">
          正则表达式
        </Typography>
        <Box mt={1}>
          <pre style={{ color: theme.palette.text.primary, background: theme.palette.background.default, padding: '10px', borderRadius: '8px', fontSize: '12px' }}>
            {JSON.stringify(policy.dataset_config.regex_patterns, null, 2)}
          </pre>
        </Box>
      </Box>

      {policy.permission === 'allow' && (
        <>
          <Divider sx={{ marginBottom: '16px' }} />
          <Box>
            <Typography variant="subtitle1" fontWeight="bold">
              行过滤器
            </Typography>
            <Box mt={1}>
              <pre style={{ color: theme.palette.text.primary, background: theme.palette.background.default, padding: '10px', borderRadius: '8px', fontSize: '12px' }}>
                {JSON.stringify(policy.dataset_config.rows_filters, null, 2)}
              </pre>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

export default PolicyDetail;
