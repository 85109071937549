import React, { useState, useEffect, useRef } from 'react';
import { Box, Button, Typography, Avatar, Grow, ClickAwayListener, TextField, IconButton,Tooltip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import NightsStayIcon from '@mui/icons-material/NightsStay';
import { Logout as LogoutIcon, ArrowDropDown as ArrowDropDownIcon, Check as CheckIcon, Close as CloseIcon, Edit as EditIcon } from '@mui/icons-material';
import { getUsers, updateUser } from '../api/projectApi';
import PhoneBinding from '../pages/PhoneBinding';
import LinkIcon from '@mui/icons-material/Link';
import { useAppContext1 } from './Context';
import QR from '../assets/QR.jpg';
import QrCodeIcon from '@mui/icons-material/QrCode';
import logo from '../assets/favicon.jpg';



const TenantInfo = ({ handleLogout, isDarkMode, handleThemeModel }) => {
  const theme = useTheme();
  const [showDetails, setShowDetails] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [tempName, setTempName] = useState('');
  const [showPhoneBinding, setShowPhoneBinding] = useState(false);
  const [nameError, setNameError] = useState('');
  const { user, setUser } = useAppContext1();

  const MAX_NAME_LENGTH = 15;

  const anchorRef = useRef(null);

  // 初始化加载资源
  useEffect(() => {
    const fetchDatasources = async () => {
      const data = await getUsers();
      setUser(data);
    };

    fetchDatasources();
  }, []);

  const handleToggleDetails = () => {
    setShowDetails((prev) => !prev);
  };

  const handleClickAway = () => {
    if (showDetails) {
      setShowDetails(false);
      setIsEditing(false);
      setNameError('');
    }
  };

  const handleEditClick = () => {
    setIsEditing(true);
    setTempName(user.name); // 设置默认值为当前用户名
  };
  const handleCancelClick = () => {
    setTempName('');
    setIsEditing(false);
    setNameError('');
  };

  const handleSaveClick = async () => {
    if (tempName.length > MAX_NAME_LENGTH) {
      return; // 如果名字超过长度限制，直接返回，不执行保存操作
    }
    const data = await updateUser(tempName);
    setUser({ ...user, name: data.name });
    setIsEditing(false);
    setNameError('');
  };


  const handleNameChange = (e) => {
    const name = e.target.value;
    setTempName(name);
    if (name.length > MAX_NAME_LENGTH) {
      setNameError(`用户名不能超过 ${MAX_NAME_LENGTH} 个字符`);
    } else {
      setNameError('');
    }
  };

  const handlePhoneBindingOpen = () => {
    setShowPhoneBinding(true);
  };

  const handlePhoneBindingClose = () => {
    setShowPhoneBinding(false);
  };

  const handlePhoneBindingSuccess = async (phoneNumber) => {
    setUser({ ...user, phone: phoneNumber });
    setShowPhoneBinding(false);
  };
  // TODO 这个代码可以删掉吗
  useEffect(() => {
    if (showDetails) {
      // 在浮窗打开时禁用页面滚动
      document.body.style.overflow = 'hidden';
    } else {
      // 在浮窗关闭时启用页面滚动
      document.body.style.overflow = 'auto';
    }
  }, [showDetails]);


  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '100%' }}>
        {/* <Box
          onClick={handleThemeModel}
          sx={{
            marginLeft: '20px',
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
          }}
        >
          {isDarkMode ? (
            <NightsStayIcon style={{ width: 24, height: 24, color: theme.palette.text.primary }} />
          ) : (
            <WbSunnyIcon style={{ width: 24, height: 24, color: theme.palette.text.primary }} />
          )}
        </Box> */}
        {/* 去除切换主题色功能 */}
        <Tooltip
          title={
            <Box sx={{ padding: '8px', display: 'flex', flexDirection: 'column', alignItems: 'center', maxWidth: '180px', maxHeight: '230px' }}>
              <Typography variant="body2" sx={{ marginBottom: '8px' }}>
                关注 AskTable 官方公众号
              </Typography>
              <img 
                src={QR} 
                alt="QR Code" 
                style={{ width: '170px', height: '170px', objectFit: 'contain' }} 
              />
            </Box>
          }
          arrow
        >
          <IconButton>
            <QrCodeIcon />
          </IconButton>
        </Tooltip>
        <Box
          sx={{
            position: 'relative',
            '&:hover': {
              cursor: 'pointer',
              textDecoration: 'underline',
            },
          }}
        >
          <Typography variant="body1" sx={{ color: theme.palette.text.primary, fontSize: '1rem', padding: '10px', borderRadius: '50px' }}>
            <a href='https://docs.asktable.com/docs/introduction/what-is-asktable' style={{ color: theme.palette.text.primary }} target="_blank" rel="noopener noreferrer">
              帮助文档
            </a>
          </Typography>
        </Box>
        <Box
          onClick={handleToggleDetails}
          ref={anchorRef}
          sx={{
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            ml: 2,
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: 1,
            padding: '4px 8px',
            '&:hover': {
              backgroundColor: theme.palette.action.hover,
            },
          }}
        >
          {/* 用户名显示 */}
          <Typography variant="body1" sx={{ color: theme.palette.text.primary, fontSize: '1rem', fontWeight: 'bold' }}>
            {user.name}
          </Typography>
          {/* 下拉图标 */}
          <ArrowDropDownIcon sx={{ color: theme.palette.text.primary }} />
        </Box>

        <Grow in={showDetails} style={{ transformOrigin: 'right top' }}>
        <Box
          sx={{
            position: 'absolute',
            top: 'calc(100% + 10px)',
            right: 20,
            p: 3,
            borderRadius: 2,
            backgroundColor: isDarkMode ? '#497263' : '#f3fbf8',
            color: isDarkMode ? '#ffffff' : '#497263',
            boxShadow: '0px 4px 5px rgba(0, 0, 0, 0.1)',
            zIndex: 10,
            width: '320px',
          }}
        >
            {/* 用户头像 */}
            <img 
              src={logo} 
              alt="Logo" 
              style={{
                width: 70,
                height: 70,
                display: 'block',
                margin: '0 auto 16px auto'  // 这相当于 mx: 'auto' 和 mb: 2
              }}
            />
            {/* <Avatar
            sx={{
              width: 70,
              height: 70,
              mx: 'auto',
              mb: 2,
              backgroundColor: isDarkMode ? '#1b5e20' : '#0f7b4f'
            }}
          >
            <img src="../assets/favicon.jpg"/>
          </Avatar> */}

            {/* 编辑用户名 */}
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 3 }}>
            {!isEditing ? (
              <Box sx={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Typography
                  variant="h6"
                  sx={{ 
                    textAlign: 'center', 
                    fontWeight: 'bold',
                    color: isDarkMode ? '#c8e6c9' : '#1b5e20',
                  }}
                >
                  {user.name}
                </Typography>
                <IconButton 
                  onClick={handleEditClick} 
                  sx={{ 
                    position: 'absolute',
                    right: '-25px',
                    p: 0.5, 
                    backgroundColor: 'transparent', 
                    '&:hover': { backgroundColor: 'transparent' }, 
                    '&:focus': { backgroundColor: 'transparent' } 
                  }}
                  disableRipple 
                  disableFocusRipple
                >
                  <EditIcon sx={{ color: theme.palette.text.primary, fontSize: '18px' }} />
                </IconButton>
              </Box>
            ) : (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <TextField
                value={tempName}
                onChange={handleNameChange}
                variant="outlined"
                size="small"
                sx={{ 
                  width: '120px',
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: nameError ? 'red' : 'inherit',
                    },
                  },
                }}
                error={!!nameError}
              />
              <IconButton onClick={handleSaveClick} sx={{ ml: 1 }} disabled={!!nameError}>
                <CheckIcon sx={{ color: nameError ? theme.palette.action.disabled : theme.palette.success.main }} />
              </IconButton>
              <IconButton onClick={handleCancelClick} sx={{ ml: 1 }}>
                <CloseIcon sx={{ color: theme.palette.error.main }} />
              </IconButton>
            </Box>
            {nameError && (
              <Typography variant="caption" sx={{ color: 'red', mt: 1 }}>
                {nameError}
              </Typography>
            )}
          </Box>
            )}
          </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <Typography variant="body2" sx={{ minWidth: '60px', color: isDarkMode ? '#81c784' : '#388e3c', display: 'inline-flex' }}>
                用户ID
              </Typography>
              <Typography variant="body2" sx={{ color: isDarkMode ? '#81c784' : '#388e3c', display: 'inline-flex' }}>
                {user.id}
              </Typography>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <Typography variant="body2" sx={{ minWidth: '60px', color: isDarkMode ? '#81c784' : '#388e3c', display: 'inline-flex' }}>
                手机号
              </Typography>
              {user.phone ? (
                <Typography variant="body2" sx={{ color: isDarkMode ? '#81c784' : '#388e3c', display: 'inline-flex' }}>
                  {user.phone}
                </Typography>
              ) : (
                <>
              <Typography variant="body2" sx={{ color: isDarkMode ? '#81c784' : '#388e3c', display: 'inline-flex'}}>
                绑定手机号
              </Typography>
              <LinkIcon sx={{ml: '10px'}} onClick={handlePhoneBindingOpen} />
               </>
              )}
            </Box>

            {/* <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <Typography variant="body2" sx={{ minWidth: '60px', color: isDarkMode ? '#81c784' : '#388e3c', display: 'inline-flex' }}>
                用户角色
              </Typography>
              <Typography variant="body2" sx={{ color: isDarkMode ? '#81c784' : '#388e3c', display: 'inline-flex' }}>
                {user.user_role || '未知'}
              </Typography>
            </Box> */}

            {/* <Box sx={{ display: 'flex', alignItems: 'center', mb: 5 }}>
              <Typography variant="body2" sx={{ minWidth: '60px', color: isDarkMode ? '#81c784' : '#388e3c', display: 'inline-flex' }}>
                余额
              </Typography>
              <Typography variant="body2" sx={{ color: isDarkMode ? '#81c784' : '#388e3c', display: 'inline-flex' }}>
                {user.billing || '未知'}
              </Typography>
            </Box> */}

            <Typography variant="body2" sx={{ textAlign: 'center' }}>
              <Button sx={{ textAlign: 'center' }} variant="outlined" startIcon={<LogoutIcon />} onClick={handleLogout}>
                退出登录
              </Button>
            </Typography>
          </Box>
        </Grow>
        <PhoneBinding
          open={showPhoneBinding}
          onClose={handlePhoneBindingClose}
          onBind={handlePhoneBindingSuccess}
          userData={user}
        />
      </Box>
    </ClickAwayListener>
  );
};

export default TenantInfo;
