import React, { useState } from 'react';
import { Box, Typography, IconButton, TextField } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';
import { updateProjectName } from '../../api/projectApi';
import { useProjectContext } from '../../components/Context';

const ProjectNameEditor = () => {
  const theme = useTheme();
  const [isEditing, setIsEditing] = useState(false);
  const [nameError, setNameError] = useState('');
  const [tempName, setTempName] = useState('');

  const {projectData, setProjectData, custom_feedback} = useProjectContext();

  const handleEditClick = () => {
    setTempName(projectData.project_name);
    setIsEditing(true);
  };

  const handleCancelClick = () => {
    setIsEditing(false);
    setNameError('');
  };

  const handleNameChange = (e) => {
    const value = e.target.value;
    setTempName(value);
    if (value.length > 20) {
      setNameError('项目名字不能超过20个字符');
    } else {
      setNameError('');
    }
  };

  const saveProjectName = async () => {
    if (tempName > 20) {
      setNameError('项目名字不能超过20个字符');
      return;
    }
    // TODO 其实这里有点担心,如果用户在输入完成的一瞬间点击保存, 是不是读的还是旧的值
    const data = await custom_feedback(updateProjectName, projectData.project_id, tempName);
    if(data){
      setProjectData({...projectData, project_name: data.project_name});
      setIsEditing(false);
      setNameError('');
    }
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {!isEditing ? (
        <Box sx={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
          <Typography variant="h6" sx={{ fontWeight: 'bold', color: theme.palette.primary.main }}>
            {projectData.project_name}
          </Typography>
          <IconButton
            onClick={handleEditClick}
            sx={{
              ml: 1,
              p: 0.5,
              backgroundColor: 'transparent',
              '&:hover': { backgroundColor: 'transparent' },
            }}
            disableRipple
          >
            <EditIcon sx={{ color: theme.palette.text.primary, fontSize: '18px' }} />
          </IconButton>
        </Box>
      ) : (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <TextField
            value={tempName}
            onChange={handleNameChange}
            variant="outlined"
            size="small"
            sx={{
              width: '200px',
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: nameError ? 'red' : 'inherit',
                },
              },
            }}
            error={!!nameError}
            helperText={nameError}
          />
          <IconButton onClick={saveProjectName} sx={{ ml: 1 }} disabled={!!nameError}>
            <CheckIcon sx={{ color: nameError ? theme.palette.action.disabled : theme.palette.success.main }} />
          </IconButton>
          <IconButton onClick={handleCancelClick} sx={{ ml: 1 }}>
            <CloseIcon sx={{ color: theme.palette.error.main }} />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

export default ProjectNameEditor;