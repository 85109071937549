import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Select, MenuItem, InputLabel, FormControl,FormHelperText   } from '@mui/material';
import { createExtAPIRoute, updateExtAPIRoute } from '../../api/askTableApi';

const ExtAPIRouteDialog = ({ open, onClose, onSave, route, extAPIId }) => {
  const [name, setName] = useState('');
  const [method, setMethod] = useState('');
  const [path, setPath] = useState('');
  const [pathParamsDesc, setPathParamsDesc] = useState('');
  const [queryParamsDesc, setQueryParamsDesc] = useState('');
  const [bodyParamsDesc, setBodyParamsDesc] = useState('');

  useEffect(() => {
    if (route) {
      setName(route.name);
      setMethod(route.method);
      setPath(route.path);
      setPathParamsDesc(route.path_params_desc);
      setQueryParamsDesc(route.query_params_desc);
      setBodyParamsDesc(route.body_params_desc);
    } else {
      setName('');
      setMethod('');
      setPath('');
      setPathParamsDesc('');
      setQueryParamsDesc('');
      setBodyParamsDesc('');
    }
  }, [route]);

  const handleSave = async () => {
    const payload = { name, method, path, path_params_desc: pathParamsDesc, query_params_desc: queryParamsDesc, body_params_desc: bodyParamsDesc };
    try {
      const savedRoute = route ? await updateExtAPIRoute(extAPIId, route.id, payload) : await createExtAPIRoute(extAPIId, payload);
      onSave(savedRoute);
    } catch (error) {
      console.error('Failed to save route', error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle >{route ? '编辑 Route' : '创建 Route'}</DialogTitle>
      <DialogContent>
        <TextField label="名字" fullWidth margin="dense" value={name} onChange={(e) => setName(e.target.value)} />
        <FormControl fullWidth margin="dense">
          <InputLabel>HTTP 方法</InputLabel>
          <Select
            value={method}
            onChange={(e) => setMethod(e.target.value)}
            label="HTTP 方法"
            MenuProps={{
                PaperProps: {
                  sx: {
                    width: 'auto',
                    minWidth: '100%'
                  }
                },
                MenuListProps: {
                  sx: {
                    padding: 0
                  }
                }
              }}
          >
            <MenuItem value="GET">GET</MenuItem>
            <MenuItem value="POST">POST</MenuItem>
            <MenuItem value="PUT">PUT</MenuItem>
            <MenuItem value="DELETE">DELETE</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth margin="dense">
          <TextField label="路径 Path" fullWidth margin="dense" value={path} onChange={(e) => setPath(e.target.value)} />
          <FormHelperText>可以在path里面使用 &#123;&#125; 来表示参数，比如: /chats/&#123;chat_id&#125;</FormHelperText>
        </FormControl>
        <TextField label="Path 参数" fullWidth margin="dense" value={pathParamsDesc} 
          placeholder='对路径path中path参数进行说明'    sx={{
            '& .MuiInputBase-input::placeholder': {
              color: "secondary", // 设置 placeholder 颜色为白色
            },
          }}
         onChange={(e) => setPathParamsDesc(e.target.value)} multiline />
        <TextField label="Query 参数" fullWidth margin="dense" value={queryParamsDesc}
          placeholder='对路径path中Query参数进行说明'    sx={{
            '& .MuiInputBase-input::placeholder': {
              color: "secondary", // 设置 placeholder 颜色为白色
            },
          }} onChange={(e) => setQueryParamsDesc(e.target.value)} multiline />
        <TextField label="Body 参数" fullWidth margin="dense" value={bodyParamsDesc}
          placeholder='对路径path中body参数进行说明'    sx={{
            '& .MuiInputBase-input::placeholder': {
              color: "secondary", // 设置 placeholder 颜色为白色
            },
          }} onChange={(e) => setBodyParamsDesc(e.target.value)} multiline />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>取消</Button>
        <Button onClick={handleSave} color="primary">保存</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ExtAPIRouteDialog;
