import React, { useState, useEffect } from 'react';
import { 
  Box, Button, Typography, Paper, Grid, FormControl, InputLabel, 
  Select, MenuItem, Snackbar, TextField, CircularProgress, Dialog, 
  DialogTitle, DialogContent, DialogActions, IconButton
} from '@mui/material';
import { getDatasources, getRoles, getRoleVariables, generateSQL, generateAnswer } from '../api/askTableApi';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

const ShowCode = ({ sqlValue, isEnabled, generationType }) => {
  const [copied, setCopied] = useState(false);
  const [value, setValue] = useState('');
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  useEffect(() => {
    const codeValue = generationType === 'sql'
      ? `#生成SQL代码示例:\nfrom asktable import AskTable\nat = AskTable(api_key='<ASKTABLE_API_KEY>')
\nsql = at.q2s.create(datasource_id='${sqlValue[1]}', '${sqlValue[0]}')\nprint(sql.prepared_statement)\nprint(sql.params)`
      : `#生成答案代码示例:\nfrom asktable import AskTable\nat = AskTable(api_key='<ASKTABLE_API_KEY>')
\nanswer = at.q2a.create(datasource_id='${sqlValue[1]}', '${sqlValue[0]}')\nprint(answer.text)`;
    setValue(codeValue);
  }, [sqlValue, generationType]);

  const handleCopyClick = () => {
    navigator.clipboard.writeText(value);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <>
      <Button 
        variant="outlined" 
        onClick={() => setIsDialogOpen(true)} 
        disabled={!isEnabled}
      >
        查看代码
      </Button>
      <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)} maxWidth="md" fullWidth>
        <DialogTitle>
          <Typography>代码预览</Typography>
          <IconButton
            onClick={() => setIsDialogOpen(false)}
            sx={{
              position: 'absolute',
              top: 13,
              right: 13,
              '&:hover': {
                color: (theme) => theme.palette.primary.main,
              },
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2 }}>
            <TextField
              fullWidth
              multiline
              minRows={6}
              variant="outlined"
              value={value}
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <IconButton onClick={handleCopyClick} edge="end" sx={{
                    position: 'relative', top: -54,
                    '&:hover': {
                      color: (theme) => theme.palette.primary.main,
                    },
                  }}>
                    {copied ? <CheckIcon /> : <ContentCopyIcon />}
                  </IconButton>
                ),
              }}
              sx={{ bgcolor: '#e0e0e0' }}
            />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

const SQLGenerationPage = () => {
    const [datasources, setDatasources] = useState([]);
    const [roles, setRoles] = useState([]);
    const [roleVariables, setRoleVariables] = useState([]);
    const [roleVariableValues, setRoleVariableValues] = useState({});
    const [selectedDatasource, setSelectedDatasource] = useState('');
    const [selectedRole, setSelectedRole] = useState('');
    const [question, setQuestion] = useState('');
    const [loading, setLoading] = useState(false);
    const [result, setResult] = useState(null);
    const [error, setError] = useState({ message: '', code: '' });
    const [selectedOption, setSelectedOption] = useState('');
    const [isCodeViewEnabled, setIsCodeViewEnabled] = useState(false);
  
    useEffect(() => {
      const fetchDatasourcesAndRoles = async () => {
        try {
          const [datasourceData, roleData] = await Promise.all([
            getDatasources(10, 1, 'desc'),
            getRoles(20, 1, 'desc')
          ]);
          setDatasources(datasourceData.data);
          setRoles(roleData.data);
        } catch (error) {
          console.error('Failed to fetch datasources or roles:', error);
          setError({ message: error.message, code: error.code });
        }
      };
  
      fetchDatasourcesAndRoles();
    }, []);
  
    useEffect(() => {
      if (selectedRole && selectedDatasource) {
        fetchRoleVariables(selectedRole, selectedDatasource);
      } else if (!selectedRole && selectedDatasource) {
        setRoleVariables([]);
        setRoleVariableValues({});
      }
    }, [selectedRole, selectedDatasource]);
  
    const fetchRoleVariables = async (roleId, datasourceId) => {
      try {
        const variableData = await getRoleVariables(roleId, datasourceId);
        const variables = variableData[datasourceId] || [];
        setRoleVariables(variables);
        setRoleVariableValues({});
      } catch (error) {
        console.error('Failed to fetch role variables:', error);
        setError({ message: error.message, code: error.code });
      }
    };
  
    const handleGenerate = async () => {
      if (!selectedDatasource || !question) {
        alert('请选择数据源并输入问题！');
        return;
      }
  
      setLoading(true);
      setResult(null);
      setError({ message: '', code: '' });
  
      try {
        // Enable the code view button as soon as we start the generation process
        setIsCodeViewEnabled(true);
  
        const payload = {
          datasource_id: selectedDatasource,
          role_id: selectedRole,
          role_vars: roleVariableValues,
          question: question,
          max_rows: selectedOption === 'answer' ? 0 : undefined,
        };
        const response = selectedOption === 'sql' 
          ? await generateSQL(payload)
          : await generateAnswer(payload);
        setResult(response);
      } catch (error) {
        console.error(`Failed to generate ${selectedOption}:`, error);
        setError({ message: error.message, code: error.code });
        // Disable the code view button if an error occurs
        setIsCodeViewEnabled(false);
      } finally {
        setLoading(false);
      }
    };
  
    return (
      <Box className="item-page">
        <Typography variant="h4" gutterBottom>输入您的问题，快速查询数据</Typography>
        
        <Paper elevation={1} sx={{ padding: '24px', marginBottom: '24px' }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth>
                <InputLabel id="generate-type-label">选择类型</InputLabel>
                <Select
                  labelId="generate-type-label"
                  value={selectedOption}
                  onChange={(e) => setSelectedOption(e.target.value)}
                  label="选择类型"
                  MenuProps={{
                    PaperProps: {
                      style: {width: 'auto', maxWidth: 'none', 
                      },
                    },
                  }}
                >
                  <MenuItem value="answer" selected>查数据</MenuItem>
                  <MenuItem value="sql">生成 SQL</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth>
                <InputLabel id="datasource-label">选择数据源</InputLabel>
                <Select
                  labelId="datasource-label"
                  value={selectedDatasource}
                  onChange={(e) => setSelectedDatasource(e.target.value)}
                  label="选择数据源"
                  MenuProps={{
                    PaperProps: {
                      style: {width: 'auto', maxWidth: 'none', 
                      },
                    },
                  }}
                >
                  {datasources.map((ds) => (
                    <MenuItem key={ds.id} value={ds.id}>
                      <Box display="flex" flexDirection="column">
                        <Typography variant="body1" >{ds.name ? ds.name : '未命名数据源'}</Typography>
                      </Box>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth>
                <InputLabel id="role-label">扮演角色</InputLabel>
                <Select
                  labelId="role-label"
                  value={selectedRole}
                  onChange={(e) => setSelectedRole(e.target.value)}
                  label="扮演角色"
                  MenuProps={{
                    PaperProps: {
                      style: {width: 'auto', maxWidth: 'none', 
                      },
                    },
                  }}
                >
                  <MenuItem value="">
                    <em>不做出选择（拥有全部数据权限）</em>
                  </MenuItem>
                  {roles.map((role) => (
                    <MenuItem key={role.id} value={role.id}>{role.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
  
          <TextField
            fullWidth
            label="输入您的问题"
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
            sx={{ marginTop: '24px', marginBottom: '24px' }}
            multiline
            rows={3}
          />
  
          {roleVariables.length > 0 && (
            <Box sx={{ marginBottom: '24px' }}>
              <Typography variant="h6" gutterBottom>参数</Typography>
              <Grid container spacing={2}>
                {roleVariables.map((variable) => (
                  <Grid item xs={12} sm={6} key={variable}>
                    <TextField
                      label={variable}
                      value={roleVariableValues[variable] || ''}
                      onChange={(e) => setRoleVariableValues(prev => ({ ...prev, [variable]: e.target.value }))}
                      fullWidth
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          )}
  
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleGenerate}
              disabled={!selectedOption || loading}
              sx={{ minWidth: '180px', marginRight: '16px' }}
            >
              {loading ? <CircularProgress size={24} color="inherit" /> : '开始'}
            </Button>
            <ShowCode 
              sqlValue={question && selectedDatasource ? [question, selectedDatasource] : ''}
              isEnabled={isCodeViewEnabled}
              generationType={selectedOption}
            />
          </Box>
        </Paper>
  
        {result && (
          <Paper elevation={1} sx={{ padding: '24px', marginTop: '24px', overflow: 'auto', maxHeight: '400px' }}>
            <Typography variant="h6" gutterBottom>{selectedOption === 'sql' ? '生成的 SQL 语句' : '回答：'}</Typography>
            {selectedOption === 'sql' ? (
              <>
                <pre style={{ backgroundColor: '#f5f5f5', padding: '16px', borderRadius: '4px', overflowX: 'auto' }}>
                  {result.prepared_statement}
                </pre>
                <Typography variant="h6" gutterBottom sx={{ marginTop: '16px' }}>参数</Typography>
                <pre style={{ backgroundColor: '#f5f5f5', padding: '16px', borderRadius: '4px', overflowX: 'auto' }}>
                  {JSON.stringify(result.params, null, 2)}
                </pre>
              </>
            ) : (
              <Typography variant="body1" sx={{ whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}>
                {result.text}
              </Typography>
            )}
          </Paper>
        )}
  
        <Snackbar
          open={!!error.message}
          autoHideDuration={6000}
          onClose={() => setError({ message: '', code: '' })}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Paper sx={{ padding: '16px', backgroundColor: '#f44336', color: 'white' }}>
            <Typography variant="body1">
              <strong>Error {error.code}:</strong> {error.message}
            </Typography>
          </Paper>
        </Snackbar>
      </Box>
    );
  };
  
  export default SQLGenerationPage;