import React, { createContext, useContext, useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';

const AppContext = createContext();
const ProjectContext = createContext();
const AppContext1 = createContext();

export const AppProvider = ({ children }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isSetPToken, setIsSetPToken] = useState(false);
  const [chats, setChats] = useState([]);

  return (
    <AppContext.Provider value={{ 
      dialogOpen, setDialogOpen, 
      isSetPToken, setIsSetPToken,
      chats, setChats
    }}>
      {children}
    </AppContext.Provider>
  );
};

// TODO 这个也不应该是全局的, 只有有顶部栏的才需要, 也许包裹那里可以变一下, 先简单的在顶部栏获取数据吧
export const AppProvider1 = ({ children }) => {
  const [user, setUser] = useState('');

  return (
    <AppContext1.Provider value={{ user, setUser }}>
      {children}
    </AppContext1.Provider>
  );
};

export const ProjectProvider = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const urlProjectId = location.pathname.split('/')[1];
  const [projectData, setProjectData] = useState('');
  const [selectedTab, setSelectedTab] = useState(0);

  const custom_feedback = async (requestFunc, ...args) => {
    try {
      const response = await requestFunc(...args);
      return response;
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
      return null;
    }
  };

  return (
    <ProjectContext.Provider value={{ urlProjectId, projectData, setProjectData, selectedTab, setSelectedTab, custom_feedback }}>
      {children}
    </ProjectContext.Provider>
  );
};

export const useAppContext = () => useContext(AppContext);
export const useProjectContext = () => useContext(ProjectContext);
export const useAppContext1 = () => useContext(AppContext1);