import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Typography,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Snackbar,
  Alert,
  Paper,
  CircularProgress,
  TablePagination,
  Dialog,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import LinkIcon from '@mui/icons-material/Link';
import { getExtAPIs, deleteExtAPI } from '../../api/askTableApi';
import ExtAPIDialog from './ExtAPIDialog';
import ExtAPIRouteList from './ExtAPIRouteList';
import ExtAPIRouteDialog from './ExtAPIRouteDialog';
import { formatDistanceToNow } from 'date-fns';
import { zhCN } from 'date-fns/locale';
import { useAppContext } from '../../components/Context';

const ExtAPIList = () => {
  const [extAPIs, setExtAPIs] = useState([]);
  const [selectedExtAPI, setSelectedExtAPI] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [routeDialogOpen, setRouteDialogOpen] = useState(false);
  const [selectedRoute, setSelectedRoute] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    const fetchExtAPIs = async () => {
      try {
        const response = await getExtAPIs(20, 1, 'desc');
        setExtAPIs(response.data);
      } catch (error) {
        setError('Failed to fetch ExtAPIs');
        setSnackbarOpen(true);
      } finally {
        setLoading(false);
      }
    };
    fetchExtAPIs();
  }, []);
  // project 更新，重新加载信息
  useEffect(() => {
    const fetchExtAPIs = async () => {
      try {
        const response = await getExtAPIs(20, 1, 'desc');
        setExtAPIs(response.data);
      } catch (error) {
        setError('Failed to fetch ExtAPIs');
        setSnackbarOpen(true);
      } finally {
        setLoading(false);
      }
    };
    fetchExtAPIs();
  }, []);

  const handleEdit = (extAPI) => {
    setSelectedExtAPI(extAPI);
    setDialogOpen(true);
  };

  const handleDelete = async (extAPIId) => {
    if (window.confirm('确定要删除这个ExtAPI吗？')) {
      try {
        await deleteExtAPI(extAPIId);
        setExtAPIs(extAPIs.filter(extAPI => extAPI.id !== extAPIId));
        setSnackbarMessage('删除成功');
        setSnackbarOpen(true);
      } catch (error) {
        setError('删除失败');
        setSnackbarOpen(true);
      }
    }
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setSelectedExtAPI(null);
  };

  const handleSave = (extAPI) => {
    if (selectedExtAPI) {
      setExtAPIs(extAPIs.map(api => api.id === extAPI.id ? extAPI : api));
    } else {
      setExtAPIs([extAPI, ...extAPIs]);
    }
    handleDialogClose();
  };

  const handleCreate = () => {
    setSelectedExtAPI(null);
    setDialogOpen(true);
  };

  const handleShowRoutes = (extAPI) => {
    setSelectedExtAPI(extAPI);
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
    setSelectedExtAPI(null);
  };

  const handleRouteDialogOpen = (route = null) => {
    setSelectedRoute(route);
    setRouteDialogOpen(true);
  };

  const handleRouteDialogClose = () => {
    setRouteDialogOpen(false);
    setSelectedRoute(null);
  };

  const handleRouteSave = () => {
    setRouteDialogOpen(false);
    // 你可以在此处添加刷新或其他逻辑
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
 
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box className="item-page">
      <Typography>API 工具</Typography>
      <Box className="guide-section">
        <Typography gutterBottom>
        API 工具可以提供给 Bot，以扩展 Bot 的能力。
        </Typography>
      </Box>
      <Button variant="outlined" color="primary" onClick={handleCreate}>
        添加 API 工具
      </Button>
      {loading ? (
        <CircularProgress />
      ) : (
        <TableContainer component={Paper} sx={{ marginTop: 2 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>名称</TableCell>
                <TableCell>根 URL</TableCell>
                <TableCell>HTTP 头</TableCell>
                <TableCell>创建时间</TableCell>
                <TableCell>修改时间</TableCell>
                <TableCell>操作</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {extAPIs.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((extAPI) => (
                <TableRow key={extAPI.id}  style={{height: '70px'}}>
                  <TableCell>{extAPI.id}</TableCell>
                  <TableCell>{extAPI.name}</TableCell>
                  <TableCell>{extAPI.base_url}</TableCell>
                  <TableCell>
                    {extAPI.headers
                      ? JSON.stringify(extAPI.headers).length > 50
                        ? `${JSON.stringify(extAPI.headers).substring(0, 50)}...`
                        : JSON.stringify(extAPI.headers)
                      : ''}
                  </TableCell>
                  <TableCell>{formatDistanceToNow(new Date(extAPI.created_at), { addSuffix: true, locale: zhCN })}</TableCell>
                  <TableCell>{extAPI.updated_at ? new Date(extAPI.updated_at).toLocaleString() : '无'}</TableCell>
                  <TableCell>
                    <IconButton onClick={(e) => { e.stopPropagation(); handleShowRoutes(extAPI); }}>
                      <LinkIcon fontSize="small" />
                    </IconButton>
                    <IconButton onClick={(e) => { e.stopPropagation(); handleEdit(extAPI); }}>
                      <EditIcon fontSize="small" />
                    </IconButton>
                    <IconButton onClick={(e) => { e.stopPropagation(); handleDelete(extAPI.id); }}>
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              {Array.from(new Array(rowsPerPage - extAPIs.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).length)).map((_, index) => (
                <TableRow key={`empty-${index}`}>
                  <TableCell colSpan={7} />
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[6]}
            component="div"
            count={extAPIs.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelDisplayedRows={({ from, to, count }) => `页码：${page + 1} / ${Math.ceil(count / rowsPerPage)}`}
          />
        </TableContainer>
      )}
      <ExtAPIDialog
        open={dialogOpen}
        onClose={handleDialogClose}
        onSave={handleSave}
        extAPI={selectedExtAPI}
      />
      <Dialog open={drawerOpen} onClose={handleDrawerClose} fullWidth maxWidth="md">
        {selectedExtAPI && (
          <ExtAPIRouteList
            extAPIId={selectedExtAPI.id}
            onCreateRoute={handleRouteDialogOpen}  // 传递创建 Route 的回调函数
            onEditRoute={handleRouteDialogOpen}    // 传递编辑 Route 的回调函数
          />
        )}
      </Dialog>
      <ExtAPIRouteDialog
        open={routeDialogOpen}
        onClose={handleRouteDialogClose}
        onSave={handleRouteSave}
        route={selectedRoute}
        extAPIId={selectedExtAPI?.id}
      />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity={error ? "error" : "success"} sx={{ width: '100%' }}>
          {snackbarMessage || error}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ExtAPIList;
