import { Card, CardContent, Typography, Link } from '@mui/material';

const AccessConfigCard = ({ dataSource }) => {
  const renderAccessConfig = () => {
    if (!dataSource.access_config) {
      return <Typography variant="body1">无访问配置信息</Typography>;
    }

      if ([ 'mysql', 'tidb', 'oceanbase', 'postgresql' ].includes(dataSource.engine)) {
      const { host, port, user, db } = dataSource.access_config;
      return (
        <>
          <Typography variant="body1"><strong>地址:</strong> {host}:{port}</Typography>
          <Typography variant="body1"><strong>数据库:</strong> {db}</Typography>
          <Typography variant="body1"><strong>用户名:</strong> {user}</Typography>
        </>
      );
    }

    if (dataSource.engine === 'excel' || dataSource.engine === 'csv') {
      const { location_url } = dataSource.access_config;
      return (
        <Typography variant="body1">
          <strong>下载地址:</strong> <Link href={location_url} target="_blank" rel="noopener noreferrer">{location_url}</Link>
        </Typography>
      );
    }

    return Object.entries(dataSource.access_config).map(([key, value]) => (
      <Typography key={key} variant="body1"><strong>{key}:</strong> {value}</Typography>
    ));
  };

  return (
    <Card variant="outlined" sx={{ mb: 3 }}>
      <CardContent>
        <Typography variant="h6" gutterBottom>访问信息</Typography>
        {renderAccessConfig()}
      </CardContent>
    </Card>
  );
};

export default AccessConfigCard;
