// themeDark.js
import { createTheme } from '@mui/material/styles';

const themeDark = createTheme({
  palette: {
    primary: {
      main: '#8AD8BC', // 按钮主题色
    },
    secondary: {
      main: '#497263', // 选中选项背景色
    },
    background: {
      default: '#0C101D', // 左侧目录主题色（黑色）
      paper: '#202327', // 顶部主题色（浅黑色）
    },
    text: {
      primary: '#FFFFFF', // 白色
      secondary: '#000000', // 黑色
      third: '#8AD8BC', // 绿色
      four: 'transparent',  // 透明
    },
    action: {
      hover: '#8b8d8f', // 选项未选中时浮动效果
      selected: '#8AD8BC', // 选中选项 hover 时
    },
    grey: {
      500: '#8b8d8f', // 按钮未选中时未 hover 效果
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderColor: '#8b8d8f', // 按钮边框颜色未 hover 效果
          transition: 'border-color 0.3s', // 按钮边框颜色切换时间 0.3 秒
          '&:hover': {
            backgroundColor: '#68ae94', // 按钮未选中时浮动效果 hover
            borderColor: '#68ae94', // 按钮边框颜色 hover 效果
            color: '#fff', // 按钮边框颜色 hover 效果, 文字
          },
          '&.Mui-selected': {
            backgroundColor: '#497263', // 选中时背景颜色
            '&:hover': {
              backgroundColor: '#8AD8BC', // 选中时再 hover 时
            },
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: 'inherit', // 取消默认颜色
          '&:hover': {
            color: '#8AD8BC', // 悬浮时变为 primary
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: '#FFFFFF', // 设置 helperText 的全局颜色
          '&.Mui-error': {
            color: '#FF6F61', // 错误状态下的 helperText 颜色
          },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent', // 列表背景颜色
          color: '#FFFFFF', // 列表字体颜色
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: '#497263', // 选中选项的背景颜色
            color: '#FFFFFF', // 选中选项的字体颜色
            '&:hover': {
              backgroundColor: '#8AD8BC', // 选中选项在 hover 时的背景颜色
              color: '#FFFFFF', // 选中选项在 hover 时的字体颜色
            },
          },
          '&:hover': {
            backgroundColor: '#8b8d8f', // 未选中选项在 hover 时的背景颜色
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          color: '#FFFFFF', // primary 文本颜色
        },
        secondary: {
          color: '#8AD8BC', // secondary 文本颜色
        },
      },
    },
    MuiOutlinedInput: { // 这里设置全局的输入框样式
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#8b8d8f', // 输入框边框颜色未 hover 效果
            transition: 'border-color 0.3s', // 输入框边框颜色切换时间 0.3 秒
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#8AD8BC', // 输入框边框颜色 hover 效果
          },
        },
        input: {
          color: '#FFFFFF', // 输入框内文本颜色
          '&::placeholder': {
            color: '#8AD8BC', // 输入框默认的文字颜色
          },
        },
      },
    },
    MuiInputLabel: { // 高亮：设置 InputLabel 组件的样式
      styleOverrides: {
        root: {
          color: '#8AD8BC', // 设置标签的全局颜色为绿色
          '&.Mui-focused': {
            color: '#8AD8BC', // 标签在获得焦点时的颜色
          },
          backgroundColor: 'transparent', // 确保标签背景颜色为透明
          '&.Mui-focused': {
            backgroundColor: 'transparent', // 聚焦时背景颜色为透明
          },
          '&.Mui-selected': {
            backgroundColor: 'transparent', // 选中时背景颜色为透明
          },
          '&.Mui-focused.Mui-selected': {
            backgroundColor: 'transparent', // 聚焦且选中时背景颜色为透明
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderColor: '#8b8d8f', // 输入框边框颜色未 hover 效果
          transition: 'border-color 0.3s', // 输入框边框颜色切换时间 0.3 秒
          '&:hover': {
            borderColor: '#68ae94', // 输入框边框颜色 hover 效果
          },
        },
        input: {
          color: '#FFFFFF', // 输入框内文本颜色
          '&::placeholder': {
            color: '#8AD8BC', // 输入框默认的文字颜色
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#202327', // 卡片对应背景
          color: '#FFFFFF', // 卡片普通文字颜色
          '& .MuiTypography-h5': {
            color: '#FFFFFF', // 卡片标题颜色
          },
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          width: '100%', // 让表格宽度占满容器
          borderCollapse: 'collapse', // 表格的边框线相互融合
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          maxHeight: '500px', // 固定表格容器的最大高度，确保分页时表格的高度是固定的
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: '#497263', // 设置表头背景颜色为深绿色
          '& .MuiTableCell-root': {
            color: '#FFFFFF', // 表头文本颜色为白色
          },
        },
      },
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          '& .MuiTableRow-root:nth-of-type(odd)': {
            backgroundColor: '#2c2f33', // 奇数行深灰色背景
          },
          '& .MuiTableRow-root:nth-of-type(even)': {
            backgroundColor: '#202327', // 偶数行浅黑色背景
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: '8px', // 调整表格单元格内边距
          borderBottom: '1px solid #8b8d8f', // 调整单元格边框颜色
          color: '#FFFFFF', // 调整单元格文本颜色
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          '& .MuiTablePagination-toolbar': {
            paddingLeft: '16px', // 调整分页组件的左侧内边距
            paddingRight: '16px', // 调整分页组件的右侧内边距
          },
          '& .MuiTablePagination-actions': {
            marginLeft: 'auto', // 将分页按钮推到右侧
          },
        },
      },
    },
  },
  typography: {
    fontFamily: 'Roboto, Arial, sans-serif', // 字体格式
  },
});

export default themeDark;
