import React, { useState, useEffect } from 'react';
import { getSecureTunnels, createSecureTunnel, deleteSecureTunnel, getSecureTunnelLinks } from '../api/askTableApi';
import { Box, Button, Typography, Grid, Paper, Dialog, DialogTitle, DialogContent, DialogActions, Table, TableBody, 
  TableCell, TableContainer, TableHead, TableRow, TextField, IconButton, Snackbar } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import IDLabel from '../components/IDLabel';
import '../global.css';
import './SecureTunnel.css';
import { formatDistanceToNow } from 'date-fns';
import { zhCN } from 'date-fns/locale';
import { useTheme } from '@mui/material/styles';
import HoverPopover from '../components/HoverPopover';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Tooltip from '@mui/material/Tooltip';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const SecureTunnel = () => {
  const theme = useTheme();

  const [secureTunnels, setSecureTunnels] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedTunnel, setSelectedTunnel] = useState(null);
  const [links, setLinks] = useState([]);
  const [tunnelName, setTunnelName] = useState('');
  const [error, setError] = useState({ message: '', code: '' });
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverId, setPopoverId] = useState(null);
  const [popoverType, setPopoverType] = useState(null);

  useEffect(() => {
    const fetchSecureTunnels = async () => {
      try {
        const data = await getSecureTunnels(20, 1, 'desc');
        setSecureTunnels(data.data);
      } catch (error) {
        console.error('Failed to fetch secure tunnels:', error);
        setError({ message: error.message, code: error.code });
        setSnackbarMessage(`Error ${error.code}: ${error.message}`);
        setSnackbarOpen(true);
      }
    };

    fetchSecureTunnels();
  }, []);

  const handlePopoverOpen = (event, id, type) => {
    setAnchorEl(event.currentTarget);
    setPopoverId(id);
    setPopoverType(type);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setPopoverId(null);
    setPopoverType(null);
  };

  const handleCreateSecureTunnel = async () => {
    try {
      const newTunnel = await createSecureTunnel({ name: tunnelName });
      setDialogOpen(false);
      setTunnelName('');
      const data = await getSecureTunnels(20, 1, 'desc');
      setSecureTunnels(data.data);
      alert(`Secure Tunnel Created! ID: ${newTunnel.id}`);
    } catch (error) {
      console.error('Failed to create secure tunnel:', error);
      setError({ message: error.message, code: error.code });
      setSnackbarMessage(`Error ${error.code}: ${error.message}`);
      setSnackbarOpen(true);
    }
  };

  const handleSelectTunnel = async (tunnel) => {
    setSelectedTunnel(tunnel);
    try {
      const data = await getSecureTunnelLinks(tunnel.id, 20, 1, 'desc');
      setLinks(data.data);
    } catch (error) {
      console.error('Failed to fetch tunnel links:', error);
      setError({ message: error.message, code: error.code });
      setSnackbarMessage(`Error ${error.code}: ${error.message}`);
      setSnackbarOpen(true);
    }
  };

  const handleDeleteTunnel = async (tunnelId) => {
    if (window.confirm("确定要删除这个安全隧道吗？")) {
      try {
        await deleteSecureTunnel(tunnelId);
        setSecureTunnels(secureTunnels.filter(tunnel => tunnel.id !== tunnelId));
        setSnackbarMessage('删除成功！');
        setSnackbarOpen(true);
      } catch (error) {
        console.error('Failed to delete secure tunnel:', error);
        setError({ message: error.message, code: error.code });
        setSnackbarMessage(`Error ${error.code}: ${error.message}`);
        setSnackbarOpen(true);
      }
    }
  };

  const handleCloseDialog = () => {
    setSelectedTunnel(null);
    setLinks([]);
    setDialogOpen(false);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const getStatusLabel = (status) => {
    switch (status) {
      case 'up':
        return (
          <Tooltip title="在线">
            <CheckCircleOutlineIcon sx={{ color: '#8AD8BC' }} />
          </Tooltip>
        );
      case 'down':
        return (
          <Tooltip title="离线">
            <ErrorOutlineIcon sx={{ color: '#bf1717' }} />
          </Tooltip>
        );
      default:
        return null;
    }
  };

  const handleCopyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      setSnackbarMessage('复制成功');
    } catch (error) {
      setSnackbarMessage('复制失败');
    }
    setSnackbarOpen(true);
  };

  return (
    <Box >
      {/* <Typography>允许 AskTable 通过安全隧道与内网的数据库进行通信</Typography>
      <Box className="guide-section">
        <Typography variant="h6" gutterBottom>
          创建安全隧道流程
          <a href="https://docs.asktable.com/docs/secure-tunnel/install-and-configure-atst" target="_blank" rel="noopener noreferrer" sx={{color:theme.palette.primary.main}} className="reference-link-small">
            (帮助文档)
          </a>
          
        </Typography>
        <ol>
          <li>点击下面的“创建安全隧道”按钮，生成一个SecureTunnel ID。</li>
          <li>使用生成的SecureTunnel ID，在服务器上运行以下命令：</li>
          <ol type="a">
            <li>国内用户：
              <Typography variant="body2" component="code" className="command-text" sx={{color:theme.palette.primary.main}} style={{ fontSize: '0.85rem' }}>
                docker run -d -P -e ASKTABLE_TOKEN=&lt;asktable_token&gt; -e SECURETUNNEL_ID=&lt;securetunnel_id&gt; registry.cn-shanghai.aliyuncs.com/datamini/asktable-secure-tunnel
              </Typography>
            </li>
            <li>海外用户：
              <Typography variant="body2" component="code" className="command-text" sx={{color:theme.palette.primary.main}} style={{ fontSize: '0.85rem' }}>
                docker run -d -P -e ASKTABLE_TOKEN=&lt;asktable_token&gt; -e SECURETUNNEL_ID=&lt;securetunnel_id&gt; datamini/asktable-secure-tunnel
              </Typography>
            </li>
          </ol>
        </ol>
      </Box>

      <Button variant="outlined" color="primary" onClick={() => setDialogOpen(true)}>创建安全隧道</Button> */}

      <Typography>AskTable 的安全隧道（ATST，AskTable Secure Tunnel）允许 AskTable 安全地访问部署在内网的数据库。通过创建安全隧道，用户可以确保数据传输的安全性，同时避免暴露内网资源。</Typography>
      <Typography>在使用 AskTable 安全隧道（ATST）之前，需要进行安装和配置。请参考
        <Box
          component="a"
          href="https://docs.asktable.com/docs/secure-tunnel/install-and-configure-atst"
          sx={{
            color: theme.palette.primary.main,
            textDecoration: 'none',
            '&:hover': {
              textDecoration: 'underline',
            },
          }}
        >
        关于 ATST 的帮助文档
      </Box>。</Typography>
      <Grid container spacing={3} mt={2}>
        {secureTunnels.map((tunnel) => (
          <Grid item xs={12} sm={12} md={6} lg={4} key={tunnel.id}>
            <Paper className="item-card">
              <Box className="item-content">
                <Box className="item-header">
                  <Typography className="item-name" noWrap onClick={() => handleSelectTunnel(tunnel)}>
                    {tunnel.name || '未命名安全隧道'}
                    {/* （{tunnel.links_count}） */}
                  </Typography>
                  {getStatusLabel(tunnel.status)}
                  <IconButton className="item-delete" onClick={(e) => { e.stopPropagation(); handleDeleteTunnel(tunnel.id); }}>
                    <DeleteIcon />
                  </IconButton>
                </Box>

                <Box display="flex" alignItems="center">
                  <Typography variant="body2" className="unique-key">ID: {tunnel.id || '无'}</Typography>
                  <IconButton
                    onClick={() => handleCopyToClipboard(tunnel.id)}
                    size="small"
                    sx={{
                      ml: 0.5,
                      p: 0.25,
                      color: 'inherit',
                      backgroundColor: 'transparent',
                      '&:hover': {
                        color: '#22684e',
                        backgroundColor: 'transparent',
                      },
                    }}
                  >
                    <ContentCopyIcon fontSize="inherit" sx={{ fontSize: '0.9rem' }} />
                  </IconButton>
                </Box>
                <Typography variant="body2" className="unique-key">连接数量: {tunnel.links_count}</Typography>
                <Typography variant="body2" className="unique-key">容器ID: {tunnel.unique_key || '无'}</Typography>
                <Typography variant="body2" className="item-time">
                  更新时间: {formatDistanceToNow(new Date(tunnel.modified_at), { addSuffix: true, locale: zhCN })}
                </Typography>
              </Box>
            </Paper>
          </Grid>
        ))}
      </Grid>

      <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>创建安全隧道</DialogTitle>
        <DialogContent>
          <TextField
            label="隧道名称"
            fullWidth
            value={tunnelName}
            onChange={(e) => setTunnelName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>取消</Button>
          <Button onClick={handleCreateSecureTunnel} color="primary">确认</Button>
        </DialogActions>
      </Dialog>

      {selectedTunnel && (
        <Dialog open={Boolean(selectedTunnel)} onClose={handleCloseDialog} maxWidth="md" fullWidth>
          <DialogTitle variant="h5" fontWeight="bold" sx={{ mt: 2 }}>安全隧道名称：{selectedTunnel.name || '未命名隧道'}</DialogTitle>
          <DialogContent>
            <Typography variant="h5"></Typography>
            <Typography className="unique-key">安全隧道ID: {selectedTunnel.id || '无'}</Typography>
            <Typography variant="h6" fontWeight="bold">通过此隧道建立的链接</Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>链接ID</TableCell>
                    <TableCell>数据源</TableCell>
                    <TableCell>对应的内网地址</TableCell>
                    <TableCell>状态</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {links.map((link) => (
                    <TableRow key={link.id}>
                      <TableCell><IDLabel id={link.id} /></TableCell>
                      <TableCell onMouseEnter={(e) => handlePopoverOpen(e, link.datasource_ids[0], 'datasource')}>
                        <IDLabel id={link.datasource_ids} />
                      </TableCell>

                      <HoverPopover
                        anchorEl={anchorEl}
                        handleClose={handlePopoverClose}
                        id={popoverId}
                        type={popoverType}
                      />

                      <TableCell>{link.target_host}:{link.target_port}</TableCell>
                      <TableCell>{getStatusLabel(link.status)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog}>关闭</Button>
          </DialogActions>
        </Dialog>
      )}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        message={snackbarMessage}
      />
    </Box>
  );
};

export default SecureTunnel;
