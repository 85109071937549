// src/components/IDLabel.js
import React, { useState } from 'react';
import { Typography } from '@mui/material';

const IDLabel = ({ id }) => {

  return (
    <Typography variant="body2" className="unique-key">{id || '无'} </Typography>
  );
};

export default IDLabel;
