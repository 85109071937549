import axios from 'axios';
import CryptoJS from 'crypto-js';

const AUTH_API_BASE_URL = window.env?.REACT_APP_AUTH_API_BASE_URL
// const AUTH_API_BASE_URL = "http://localhost:5111"

const apiProject = axios.create({
  baseURL: AUTH_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// 请求拦截器：附加 token
apiProject.interceptors.request.use(config => {
  const token = localStorage.getItem('at_token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});



const handleResponse = (response) => {
  return response; // 所有2xx响应都视为成功
};

const handleError = (error) => {
  if (error.response) {

    const { status, data } = error.response;
    const errorMessage = data.message || data.error || data.msg;
    const errorCode = data.code || status;
    return Promise.reject({ message: errorMessage, code: errorCode });
  }
  return Promise.reject(new Error('连接失败，请稍后重试'));
};

// 响应拦截器：统一处理错误
apiProject.interceptors.response.use(handleResponse, handleError);


export default apiProject;

// 对密码单向哈希的函数
const hashPassword = (password) => {
  return CryptoJS.SHA256(password).toString(CryptoJS.enc.Hex);
};


// API functions
export const privateLogin = async (email, password) => {
  const response = await apiProject.post('/users/login', {
    email: email,
    password_hash: hashPassword(password),
  });
  return response.data;
};
export const privateRegister = async (email, password) => {
  const response = await apiProject.post('/users/register', {
    email: email,
    password_hash: hashPassword(password),
  });
  return response.data;
};

export const getUsers = async () => {
  const response = await apiProject.get('/users');
  return response.data;
};

export const loginUser = async (code) => {
  const response = await apiProject.post('/users/wechat', {
    code: code,
  });
  return {
    ...response.data,
    is_bind_phone: response.data.is_bind_phone === 1
  };
};

export const updateUser = async (name) => {
  const response = await apiProject.put('/users', {
    name: name,
  });
  return response.data;
};

export const getProjects = async () => {
  const response = await apiProject.get('/projects');
  return response.data;
};

export const createProject = async (name) => {
  const response = await apiProject.post('/projects', {
    name: name,
  });
  return response.data;
};

export const updateProjectName = async (project_id,name) => {
  const response = await apiProject.put(`/projects/${project_id}`, {
    name: name,
  });
  return response.data;
};

//response.data.project_name就是项目的名称
export const getProject = async (project_id) => {
  try {
    const response = await apiProject.get(`/projects/${project_id}`);
    return response.data;
  } catch (error) {
    console.error('Failed to fetch project:', error);
    throw error;
  }
};

export const changeProject = async (id) => {
  const response = await apiProject.post('/projects/access_token', {
    project_id: id,
  });
  return response.data;
};

export const getApiKeys = async (project_id) => {
  const response = await apiProject.get(`/projects/${project_id}/api-keys`);
  return response.data;
};

export const createApiKeys = async (project_id,type) => {
  const response = await apiProject.post(`/projects/${project_id}/api-keys`, {
    type: type,
  });
  return response.data;
};

export const deleteApiKeys = async (project_id,key_id) => {
  const response = await apiProject.delete(`/projects/${project_id}/api-keys/${key_id}`);
  return response.data;
};

export const getApiKeysHistory = async (project_id) => {
  const response = await apiProject.get(`/projects/${project_id}/api-keys-history`);
  return response.data;
};

export const deleteProjectMember = async (project_id,user_id) => {
  const response = await apiProject.delete(`/projects/${project_id}/members/${user_id}`);
  return response.data;
};

export const deleteProject = async (project_id) => {
  const response = await apiProject.delete(`/projects/${project_id}`);
  return response.data;
};

export const createProjectMember = async (project_id,user_id) => {
  const response = await apiProject.post(`/projects/${project_id}/members`,{
    user_id:user_id,
  });
  return response.data;
};

export const sendVerificationCode = async (phone) => {
  const smsToken = localStorage.getItem('at_smstoken');
  const response = await apiProject.post('/users/send_verification_code', {
    phone: phone,
  }, {
    headers: { 'Authorization': `Bearer ${smsToken}` }
  });
  return response.data;
};

export const bindPhone = async (phone, code) => {
  const smsToken = localStorage.getItem('at_smstoken');
  try {
    const response = await apiProject.post('/users/bind_phone', {
      phone: phone,
      code: code,
    }, {
      headers: { 'Authorization': `Bearer ${smsToken}` }
    });

    if (response.status === 200) {
      const smsPtoken = localStorage.getItem('at_smsptoken');
      
      if (smsToken) {
        localStorage.setItem('at_token', smsToken);
        localStorage.removeItem('at_smstoken');
      }
      
      if (smsPtoken) {
        localStorage.setItem('at_ptoken', smsPtoken);
        localStorage.removeItem('at_smsptoken');
      }
      
    }

    return response.data;
  } catch (error) {
    console.error('Phone binding failed:', error);
    throw error;
  }
};

// 添加一个新的函数来检查 token 状态
export const checkTokens = () => {
  const token = localStorage.getItem('at_token');
  const ptoken = localStorage.getItem('at_ptoken');
  const smsToken = localStorage.getItem('at_smstoken');
  const smsPtoken = localStorage.getItem('at_smsptoken');

  console.log('Current token state:');
  console.log('at_token:', token);
  console.log('at_ptoken:', ptoken);
  console.log('at_smstoken:', smsToken);
  console.log('at_smsptoken:', smsPtoken);

  return {
    token,
    ptoken,
    smsToken,
    smsPtoken
  };
};