import React, { useState, useEffect } from 'react';
import {
  Box, Button, Typography, Grid, Paper, Dialog, DialogTitle, DialogContent, DialogActions,
  TextField, IconButton, Snackbar, CircularProgress, FormControl, Select, MenuItem, InputLabel, Chip, ListItemIcon, ListItemText, Checkbox
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import PublishIcon from '@mui/icons-material/Publish';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import { getBots, updateBot, createBot, deleteBot, getDatasources,getExtAPIs } from '../api/askTableApi';
import { useTheme } from '@mui/material/styles'; // 导入 useTheme
import { useAppContext, useProjectContext } from '../components/Context';

const BotDialog = ({ open, onClose, editBot, onSave }) => {
  const theme = useTheme();

  const [name, setName] = useState('');
  const [datasourceId, setDatasourceId] = useState(''); // 改为单个 id
  const [maxRows, setMaxRows] = useState(0);
  const [loading, setLoading] = useState(false);
  const [datasources, setDatasources] = useState([]);
  const [allDatasources, setAllDatasources] = useState({});
  const [error, setError] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [extAPIs, setExtAPIs] = useState([]);
  const [selectedExtAPIs, setSelectedExtAPIs] = useState([]);
  const [debugMode, setDebugMode] = useState('');
  const [sampleQuestions, setSampleQuestions] = useState([]);

  const [showAdvancedSettings, setShowAdvancedSettings] = useState(false);

  const toggleAdvancedSettings = () => {
    setShowAdvancedSettings(prevState => !prevState);
  };

  useEffect(() => {
    if (editBot) {
      setName(editBot.name);
      setDatasourceId(editBot.datasource_ids ? editBot.datasource_ids.split(',')[0].trim() : ''); // 只取第一个值
      setSelectedExtAPIs(editBot.extapi_ids ? editBot.extapi_ids.split(',').map(id => id.trim()) : []);
      setDebugMode(editBot.debug);
      setMaxRows(editBot.max_rows);
      setSampleQuestions(editBot.sample_questions ? 
        (Array.isArray(editBot.sample_questions) ? 
          editBot.sample_questions : 
          Object.values(editBot.sample_questions)) : 
        []);
    } else {
      setName('');
      setDatasourceId(''); // 重置为空字符串
      setSelectedExtAPIs([]);
      setDebugMode(true);
      setMaxRows(0);
      setSampleQuestions([]);
    }
  }, [editBot]);

  useEffect(() => {
    const fetchDatasources = async () => {
      try {
        const data = await getDatasources(100, 1, 'desc');
        const extAPIData = await getExtAPIs(20, 1, 'desc');
        setExtAPIs(extAPIData.data);
        setDatasources(data.data);
        const allData = {};
        data.data.forEach((ds) => {
          allData[ds.id] = ds;
        });
        setAllDatasources(allData);
      } catch (error) {
        console.error('无法获取数据源:', error);
        setError('无法获取数据源');
        setSnackbarOpen(true);
      }
    };

    fetchDatasources();
  }, []);

  const handleSave = async () => {
    setLoading(true);
    const botData = {
      name,
      datasource_ids: datasourceId, // 直接使用单个 id
      extapi_ids: selectedExtAPIs.join(','),
      debug: Number(debugMode),
      max_rows: maxRows,
      sample_questions: sampleQuestions,
    };
    try {
      if (editBot) {
        await updateBot(editBot.id, botData);
      } else {
        await createBot(botData);
      }
      onSave();
      onClose();
    } catch (error) {
      console.error('保存 Bot 失败:', error);
      setError('保存 Bot 失败');
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const handleDatasourceChange = (event) => {
    setDatasourceId(event.target.value);
  };

  const handleExtAPIChange = (event) => {
    setSelectedExtAPIs(event.target.value ? event.target.value : []);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleSampleQuestionChange = (index, event) => {
    const newQuestions = [...sampleQuestions];
    newQuestions[index] = event.target.value;
    setSampleQuestions(newQuestions);
  };

  const addSampleQuestion = () => {
    if (sampleQuestions.length < 3) {
      setSampleQuestions([...sampleQuestions, '']);
    }
  };

  const removeSampleQuestion = (index) => {
    const newQuestions = sampleQuestions.filter((_, i) => i !== index);
    setSampleQuestions(newQuestions);
  };

  return (
    <Dialog
      sx={{ color: theme.palette.background.default }}
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle variant="h5" sx={{ mt: 1.5, fontWeight: 'bold' }}>
        {editBot ? '编辑 Bot' : '创建 Bot'}
      </DialogTitle>
      <DialogContent sx={{ color: theme.palette.primary.main }}>
        <TextField
          label="名称"
          fullWidth
          margin="normal"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <FormControl fullWidth margin="normal" sx={{ color: theme.palette.primary.main }}>
          <InputLabel id="datasource-label">可以访问的数据源</InputLabel>
          <Select
            labelId="datasource-label"
            value={datasourceId}
            onChange={handleDatasourceChange}
            label="可以访问的数据源"
            renderValue={(selected) => (
              <Box display="flex" flexWrap="wrap" gap={0.5}>
                <Chip 
                  style={{ color: theme.palette.text.secondary }} 
                  label={allDatasources[selected]?.name || selected} 
                />
              </Box>
            )}
            MenuProps={{
              PaperProps: {
                sx: {
                  maxHeight: 300,
                  width: 250
                }
              }
            }}
          >
            {datasources.map((ds) => (
              <MenuItem key={ds.id} value={ds.id}>
                <span>{ds.name}</span>
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* 高级设置的切换按钮 */}
        <Button onClick={toggleAdvancedSettings}>
          {showAdvancedSettings ? '隐藏高级设置' : '显示高级设置'}
        </Button>

        {/* 高级设置部分 */}
        {showAdvancedSettings && (
          <>
            <FormControl fullWidth margin="normal" sx={{ color: theme.palette.primary.main }}>
              <InputLabel id="extapi-label">可以调用的接口</InputLabel>
              <Select
                labelId="extapi-label"
                multiple
                value={selectedExtAPIs}
                onChange={handleExtAPIChange}
                label="可以调用的接口"
                renderValue={(selected) => (
                  <Box display="flex" flexWrap="wrap" gap={0.5}>
                    {Array.isArray(selected) && selected.map((id) => extAPIs.find(api => api.id === id)?.name).join(', ')}
                  </Box>
                )}
                fullWidth
                MenuProps={{
                  PaperProps: {
                    sx: {
                      width: 'auto',
                      minWidth: '100%'
                    }
                  },
                  MenuListProps: {
                    sx: {
                      padding: 0
                    }
                  }
                }}
              >
                {extAPIs.map((api) => (
                  <MenuItem key={api.id} value={api.id}>
                    <ListItemIcon>
                      <Checkbox checked={selectedExtAPIs.indexOf(api.id) > -1} />
                    </ListItemIcon>
                    <ListItemText primary={api.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              label="最大行数"
              fullWidth
              margin="normal"
              type="number"
              value={maxRows}
              helperText="超过最大行数，则查询会被拒绝。0 表示不限制。"
              onChange={(e) => setMaxRows(e.target.value)}
            />

            <Typography variant="h6" gutterBottom mt={2}>常见问题</Typography>
            {sampleQuestions.map((question, index) => (
              <Box key={index} display="flex" alignItems="center" mb={1}>
                <TextField
                  label={`问题 ${index + 1}`}
                  fullWidth
                  margin="normal"
                  value={question}
                  onChange={(e) => handleSampleQuestionChange(index, e)}
                />
                <IconButton onClick={() => removeSampleQuestion(index)}>
                  <DeleteIcon />
                </IconButton>
              </Box>
            ))}
            {sampleQuestions.length < 3 && (
              <Button startIcon={<AddIcon />} onClick={addSampleQuestion}>
                添加问题
              </Button>
            )}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>取消</Button>
        <Button onClick={handleSave} color="primary" disabled={loading}>
          {loading ? <CircularProgress size={24} /> : '保存'}
        </Button>
      </DialogActions>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        message={error}
      />
    </Dialog>
  );
};

const BotExport = ({ open, onClose, Bot }) => {
  const theme = useTheme(); // 使用 useTheme 钩子获取主题对象
  const [name, setName] = useState('');
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (Bot) {
      const botScript = `<script>
window.chatbotConfig = {
  botId: ${Bot.id},    //  askTableAPIKey 和 reqTokenUrl （ 二选一 ）
  askTableAPIKey:  'YOUR_API_KEY',  //  如果有askTableAPIKey，则忽略 reqTokenUrl
  reqTokenUrl: 'YOUR_ASKTABLE_REQ_TOKENURL',    //  你自己的reqTokenUrl
  themeColor: 'black'     //主题色，默认黑色 black，其他可选：白色 white
}
</script>
<script src="https://cloud.asktable.com/chatbot.js"></script>
      `;
      setName(botScript);
    } else {
      setName('');
    }
  }, [Bot]);

  const handleCopy = () => {
    navigator.clipboard.writeText(name).then(() => {
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    }).catch(err => {
      console.error('复制失败', err);
    });
  };

  return (
    <Dialog
      sx={{ color: theme.palette.text.title  }}
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle  className="item-page"  sx={{padding:'40px 24px 20px 24px !important'}}>
        <Typography>嵌入机器人到网站</Typography>
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            top: 13,
            right: 13,
            '&:hover':{
              color: (theme) => `${theme.palette.primary.main} `, // 悬浮时图标颜色变为主题中的 primary 颜色
            },
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent >
        <TextField
          label="将以下代码嵌入到您的网站"
          fullWidth
          margin="normal"
          value={name}
          onChange={(e) => setName(e.target.value)}
          multiline
          rows={10}
          variant="outlined"
          InputLabelProps={{
            style: { color: theme.palette.text.title  } // 设置标签颜色
          }}
          sx={{
            backgroundColor: theme.palette.background.default, // 设置背景颜色
          }}
        />
        <IconButton
            onClick={handleCopy}
            sx={{
              position: 'absolute',
              top: '135px',
              right: '30px',
              zIndex: 1,
              backgroundColor: theme.palette.background.default,
              '&:hover':{
                color: (theme) => `${theme.palette.primary.main} `, // 悬浮时图标颜色变为主题中的 primary 颜色
              },
            }}
          >
            {copied ? <CheckIcon /> : <ContentCopyIcon />}
        </IconButton>
      </DialogContent>
    </Dialog>
  );
};

const Bot = () => {
  const [chatBots, setBots] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [exportOpen, setExportOpen] = useState(false);
  const [selectedBot, setSelectedBot] = useState(null);
  const [error, setError] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const theme = useTheme(); // 使用 useTheme 钩子获取主题对象
  const [updateTime, setUpdateTime] = useState('');
  const {urlProjectId} = useProjectContext();

  useEffect(() => {
    const fetchBots = async () => {
      try {
        const data = await getBots(20, 1, 'desc');
        console.warn("data:",data)
        setBots(data.data);
      } catch (error) {
        console.error('无法获取 Bots:', error);
        setError('无法获取 Bots');
        setSnackbarOpen(true);
      }
    };

    fetchBots();
  }, []);
  // project 更新，重新加载信息
  useEffect(() => {
    const fetchBots = async () => {
      try {
        const data = await getBots(20, 1, 'desc');
        console.warn("data:",data)
        setBots(data.data);
      } catch (error) {
        console.error('无法获取 Bots:', error);
        setError('无法获取 Bots');
        setSnackbarOpen(true);
      }
    };

    fetchBots();
  }, [urlProjectId]);

  const handleEditBot = (bot) => {
    console.warn("bot:",bot)
    setUpdateTime(new Date())
    bot.updated_at = new Date()
    setSelectedBot(bot);
    setDialogOpen(true);
  };

  const handleExporteBot = (bot) => {
    // console.warn("bot:",bot)
    setSelectedBot(bot);
    setExportOpen(true);
  };

  const handleDeleteBot = async (botId) => {
    if (window.confirm("确定要删除这个 Bot 吗？")) {
      try {
        await deleteBot(botId);
        setBots(chatBots.filter((bot) => bot.id !== botId));
      } catch (error) {
        console.error('删除 Bot 失败:', error);
        setError('删除 Bot 失败');
        setSnackbarOpen(true);
      }
    }
  };

  const handleCloseDialog = () => {
    setSelectedBot(null);
    setDialogOpen(false);
  };

  const handleSave = () => {
    const fetchBots = async () => {
      try {
        const data = await getBots(20, 1, 'desc');
        setBots(data.data);
      } catch (error) {
        console.error('无法获取 Bots:', error);
        setError('无法获取 Bots');
        setSnackbarOpen(true);
      }
    };

    fetchBots();
  };

  // exportBot
  const handleCloseExport = () => {
    setSelectedBot(null);
    setExportOpen(false);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box className="item-page">
      <Typography variant="h4" gutterBottom>所有 Bot</Typography>
        <Box className="guide-section">
        <Typography gutterBottom>
        你可以轻松创建一个 Bot，设置它能访问的数据范围，并将它分享出去。
        </Typography>
      </Box>
      <Button variant="outlined" color="primary" onClick={() => setDialogOpen(true)}>
        创建 Bot
      </Button>
      <Grid container spacing={3} mt={2}>
        {chatBots.map((bot) => (
          <Grid item xs={12} sm={12} md={6} lg={4} key={bot.id}>
            <Paper className="item-card" sx={{ position: 'relative', padding: 2 }}>
            <Box
              sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                gap: '1px', // 添加图标之间的间距
                position: 'absolute',
                right: '10px',
                top: '20px',
                zIndex: '2'
              }}
            >
              <IconButton
                onClick={(e) => { e.stopPropagation(); handleEditBot(bot); }}
                sx={{ 
                  padding: '0',
                  // width: '18px',
                  // height: '18px',
                  // '& .MuiSvgIcon-root': {
                  //   width: '100%',
                  //   height: '100%',
                  // },
                  '&:hover':{
                    color: (theme) => `${theme.palette.primary.main} `, // 悬浮时图标颜色变为主题中的 primary 颜色
                  },
                }}  
              >
                <EditIcon fontSize="small"  />
              </IconButton>
              <IconButton
                onClick={(e) => { e.stopPropagation(); handleExporteBot(bot); }}
                sx={{ 
                  padding: '0',
                  '&:hover':{
                    color: (theme) => `${theme.palette.primary.main} `, // 悬浮时图标颜色变为主题中的 primary 颜色
                  },
                }}  
              >
                <PublishIcon fontSize="medium"  />
              </IconButton>
              <IconButton
                className="item-delete"
                onClick={(e) => { e.stopPropagation(); handleDeleteBot(bot.id); }}
                sx={{ 
                  padding: '0',
                }}    
              >
                <DeleteIcon fontSize="small"  />
              </IconButton>
            </Box>

            <Box className="item-content" sx={{cursor:'default'}} >
              <Typography variant="h6" className="item-name" noWrap>
                {bot.name || '未命名 Bot'}
              </Typography>
              <Typography variant="body2" className="unique-key" >BotID&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp; {bot.id || '无'}</Typography>
              <Typography variant="body2" className="unique-key">数据源&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;{bot.datasource_ids || '无'}</Typography>
              <Typography variant="body2" className="unique-key">创建时间&nbsp;:&nbsp;{Intl.DateTimeFormat('default', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: false, // 24小时制
              }).format(new Date(bot.created_at))|| '无'}</Typography>
              {/* <Typography variant="body2" className="unique-key" >修改时间: {Intl.DateTimeFormat('default', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: false, // 24小时制
              }).format(new Date(bot.created_at))|| '无'}</Typography> */}
            </Box>
            </Paper>
          </Grid>
        ))}
      </Grid>
      {dialogOpen && (
        <BotDialog
          open={dialogOpen}
          onClose={handleCloseDialog}
          editBot={selectedBot}
          onSave={handleSave}
        />
      )}
      {exportOpen && (
        <BotExport
          open={exportOpen}
          onClose={handleCloseExport}
          Bot={selectedBot}
        />
      )}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        message={error}
      />
    </Box>
  );
};

export default Bot;