import React, { useState, useEffect } from 'react';
import { Box, Button, Dialog, DialogContent, DialogTitle, DialogActions, Typography, Fade, Zoom } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useProjectContext } from './Context';
import { useNavigate } from 'react-router-dom';
import { createChat, getBot } from '../api/askTableApi';
import { getProject } from '../api/projectApi';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';

const QuickStart = ({ projectId }) => {
  const [open, setOpen] = useState(false);
  const [exampleBotId, setExampleBotId] = useState(null);
  const [botName, setBotName] = useState('');
  const theme = useTheme();
  const navigate = useNavigate();

  const { urlProjectId } = useProjectContext();

  useEffect(() => {
    const fetchProjectDetails = async () => {
      try {
        const projectData = await getProject(projectId);
        setExampleBotId(projectData.example_bot_id || null);
      } catch (error) {
        console.error('获取项目详情失败:', error);
      }
    };

    fetchProjectDetails();
  }, [projectId]);

  useEffect(() => {
    const fetchBotDetails = async () => {
      if (exampleBotId) {
        try {
          const data = await getBot(exampleBotId);
          setBotName(data.name);
        } catch (error) {
          console.error('获取机器人详情失败:', error);
        }
      }
    };

    fetchBotDetails();
  }, [exampleBotId]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleStartChat = async () => {
    try {
      const newChat = await createChat({
        botId: exampleBotId,
      });
      
      if (newChat) {
        setOpen(false);
        navigate(`/${urlProjectId}/talk/chat/${newChat.id}`);
      }
    } catch (error) {
      console.error('创建聊天失败:', error);
    }
  };

  if (!exampleBotId) {
    return null;
  }

  return (
    <Box sx={{ flex: 1 }}>
      <Button 
        variant="outlined" 
        onClick={handleClickOpen}
        sx={{
          width: '50px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <RocketLaunchIcon />
      </Button>
      <Dialog 
        open={open} 
        onClose={handleClose} 
        maxWidth="sm" 
        fullWidth
        TransitionComponent={Zoom}
        transitionDuration={500}
      >
        <DialogTitle>
          <Typography variant="h4" align="center" fontWeight="bold" color="primary"sx={{ mt: 3 }}>
            与{botName}开启对话
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Fade in={open} timeout={1000}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <RocketLaunchIcon sx={{ fontSize: 80, color: theme.palette.secondary.main, mb: 2 }} />
              <Typography variant="h6" align="center" gutterBottom >
                探索 2024 巴黎奥运会
              </Typography>
              <Typography  align="center" >
                这个数据集包含了关于即将到来的 2024 巴黎奥运会的丰富信息。
                准备好开始一段奥运知识之旅了吗？
              </Typography>
            </Box>
          </Fade>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', p: 3 }}>
          <Button
            onClick={handleStartChat}
            variant="contained"
            sx={{ 
              minWidth: '120px',
              minHeight: '45px',
              backgroundColor: '#ADE3D0',
              color: '#005E3C', // 深色文字,提高对比度
              fontWeight: 'bold',
              transition: 'all 0.3s ease-in-out',
              boxShadow: '0 0 0 0 ', // 更深的颜色作为底部阴影
              '&:hover': {
                backgroundColor: '#ADE3D0', // 悬停时稍微深一点的背景色
                boxShadow: '0 5px 0 0 #7FCEB6',
                transform: 'translateY(-2px)',
              },
            }}
          >
            开始聊天
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default QuickStart;