import React, { useState, useEffect } from 'react';
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Dialog, DialogTitle, DialogContent, DialogActions, FormControl, InputLabel, Select, MenuItem, IconButton, TextField, Link } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import { createApiKeys, getApiKeys, deleteApiKeys } from '../../api/projectApi';
import { useTheme } from '@mui/material/styles';
import { useProjectContext } from '../../components/Context';

const ApiKeyManager = () => {
  const theme = useTheme();
  const [apiKeys, setApiKeys] = useState([]);
  const [apiOpen, setApiOpen] = useState(false);
  const [apiType, setApiType] = useState('');
  const [apiDetailOpen, setApiDetailOpen] = useState(false);
  const [newApiKey, setNewApiKey] = useState('');
  const [apiCode, setApiCode] = useState('');
  const [copied, setCopied] = useState({
    apikey: false,
    host: false,
    apicode: false,
  });

  const {urlProjectId} = useProjectContext();

  const HOST = "https://api.asktable.com/v1";

  useEffect(() => {
    const fetchApiKeys = async () => {
        const data = await getApiKeys(urlProjectId);
        setApiKeys(data);
    };
    fetchApiKeys();
  }, []);

  const handleSelect = (event) => {
    setApiType(event.target.value);
  };

  const createApiKey = async () => {
      const data = await createApiKeys(urlProjectId, apiType);
      setApiKeys([...apiKeys, data]);
      setNewApiKey(data.original_ak_value);
      setApiCode(`!pip install asktable\n\nfrom asktable import AskTable\nasktable = AskTable(api_key='${data.original_ak_value}')`);
      setApiOpen(false);
      setApiDetailOpen(true);
  };

  const handleDeleteApiKey = async (keyId) => {
      await deleteApiKeys(urlProjectId, keyId);
      setApiKeys(apiKeys.filter(key => key.id !== keyId));
  };

  const handleCopy = (textToCopy, type) => {
    navigator.clipboard.writeText(textToCopy).then(() => {
      setCopied((prevCopied) => ({
        ...prevCopied,
        [type]: true,
      }));
      setTimeout(() => {
        setCopied((prevCopied) => ({
          ...prevCopied,
          [type]: false,
        }));
      }, 2000);
    }).catch(err => {
      console.error(`复制${type}失败`, err);
    });
  };

  return (
    <Box>
      <Typography variant="h5" fontWeight="bold">API-Key</Typography>
      <Box>
            <Typography>当你使用 AskTable 的API时，API-Key会作为请求头中的一个字段（ Authorization: Bearer YOUR_API_KEY）发送到服务器，以证明你有权限使用该服务。</Typography>
            <Typography>API-Key是机密的，任何拥有它的人都可以访问你的OpenAI账户资源，因此需要小心保管，不要公开或泄露。</Typography>
            <Typography>
              详情请参考
              <Link href="https://docs.asktable.com/docs/api-overview/authentication-and-authorization" target="_blank" rel="noopener" sx={{ ml: 1 }}>
                关于权限的帮助文档
              </Link>。
            </Typography>
          </Box>
      <TableContainer component={Paper} sx={{ marginTop: '10px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>类型</TableCell>
              <TableCell>创建时间</TableCell>
              <TableCell>最后使用时间</TableCell>
              <TableCell>操作</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {apiKeys.map((key) => (
              <TableRow key={key.id}>
                <TableCell>{key.id}</TableCell>
                <TableCell>{key.ak_role}</TableCell>
                <TableCell>{key.created_at}</TableCell>
                <TableCell>{key.last_used_at}</TableCell>
                <TableCell>
                  <IconButton onClick={() => handleDeleteApiKey(key.id)} color="error">
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Button variant="outlined" color="primary" sx={{ marginTop: '10px' }} onClick={() => setApiOpen(true)}>
        + 创建 API-KEY
      </Button>

      <Dialog open={apiOpen} onClose={() => setApiOpen(false)}  PaperProps={{style: {minWidth: '300px',maxWidth: '600px'}}}>
        <DialogTitle variant="h5" fontWeight="bold" sx={{ mt: 2}}>创建 API-Key</DialogTitle>
        <Box>
            <Typography sx={{ paddingLeft: '24px' }}>API-Key 有两个类型：</Typography>
            <ul style={{ marginTop: '0px' }}>
              <li>admin: 具有最高权限，用于管理该项目内的全部资源。</li>
              <li>asker: 提供有限的权限，用于配置在Web 前端，访问 AskTable 服务。</li>
              <li>每个项目最多允许创建 10 个  API-Key </li>
            </ul>
          </Box>
        <IconButton
          onClick={() => setApiOpen(false)}
          sx={{ position: 'absolute', top: 13, right: 13 }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <FormControl fullWidth>
            <InputLabel >类型</InputLabel>
            <Select value={apiType} onChange={handleSelect}                 MenuProps={{
                  PaperProps: {
                    sx: {
                      width: 'auto',
                      minWidth: '100%'
                    }
                  },
                  MenuListProps: {
                    sx: {
                      padding: 0
                    }
                  }
                }}>
              <MenuItem value="admin">admin</MenuItem>
              <MenuItem value="asker">asker</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={createApiKey} variant="outlined" sx={{ mb: 2, mr:2}} >创建</Button>
        </DialogActions>
      </Dialog>

<Dialog open={apiDetailOpen} onClose={() => setApiDetailOpen(false)} fullWidth>
  <DialogTitle variant="h5" fontWeight="bold" sx={{ mt: 2 }}>API Keys</DialogTitle>
  <IconButton
    onClick={() => setApiDetailOpen(false)}
    sx={{ position: 'absolute', top: 8, right: 8 }}
  >
    <CloseIcon />
  </IconButton>
  <DialogContent>
  <Typography variant="h6" fontWeight="bold" >API-Key</Typography>
    <Typography variant="body2" color="error" sx={{ mb: 2 }}>
      请复制并妥善保存，AskTable 不会保存你的 API-Key。本条信息只会出现一次。
    </Typography>
    <TextField
      placeholder="API-Key"
      fullWidth
      value={newApiKey}
      InputProps={{
        endAdornment: (
          <IconButton
            onClick={() => handleCopy(newApiKey, 'apikey')}
            sx={{
              '&:hover': {
                color: (theme) => theme.palette.primary.main,
              },
            }}
          >
            {copied.apikey ? <CheckIcon /> : <ContentCopyIcon />}
          </IconButton>
        ),
      }}
      variant="outlined"
    />
 <Typography variant="h6" fontWeight="bold" marginTop='15px'>API 服务器</Typography>
    <TextField
      placeholder="Host"
      fullWidth
      value={HOST}
      InputProps={{
        endAdornment: (
          <IconButton
            onClick={() => handleCopy(HOST, 'host')}
            sx={{
              '&:hover': {
                color: (theme) => theme.palette.primary.main,
              },
            }}
          >
            {copied.host ? <CheckIcon /> : <ContentCopyIcon />}
          </IconButton>
        ),
      }}
      variant="outlined"
    />
 <Typography variant="h6" fontWeight="bold" marginTop='15px'>使用方法</Typography>

    <TextField
      placeholder="Usage"
      fullWidth
      value={apiCode}
      onChange={(e) => setApiCode(e.target.value)}
      multiline
      rows={5}
      variant="outlined"
      InputProps={{
        endAdornment: (
          <IconButton
            onClick={() => handleCopy(apiCode, 'apicode')}
            sx={{
              '&:hover': {
                color: (theme) => theme.palette.primary.main,
              },
            }}
          >
            {copied.apicode ? <CheckIcon /> : <ContentCopyIcon />}
          </IconButton>
        ),
      }}
      sx={{
        backgroundColor: theme.palette.background.default,
      }}
    />

    <Typography sx={{ mt: 1 }}>
      详情请参考
      <Link href="https://docs.asktable.com/docs/api-overview/authentication-and-authorization" target="_blank" rel="noopener" sx={{ ml: 1 }}>
        关于权限的帮助文档
      </Link>。
    </Typography>
  </DialogContent>
</Dialog>


      
    </Box>
  );
};

export default ApiKeyManager;