import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, Button, Typography, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import QuickStart from './QuickStart';
import ChatList from './ChatList';
import { useAppContext, useProjectContext } from './Context';

const ChatTab = () => {
  const navigate = useNavigate();
  const { 
    setDialogOpen, 
  } = useAppContext();
  const { urlProjectId, setSelectedTab } = useProjectContext();

  useEffect(() => {
    setSelectedTab(1);
  }, []);

  const handleCreateChat = () => {
    setDialogOpen(true);
    const box = document.getElementById('chatBox');
    if (box) box.remove();
    navigate(`/${urlProjectId}/talk/chat`);
  };

  const handleFunctionItemClick = (item, path) => {
    const box = document.getElementById('chatBox');
    if (box) box.remove();
    navigate(path);
  };

  const quickStartClick = () => {
    const box = document.getElementById('chatBox');
    if (box) {
      box.remove();
    }
  };

  // 获取当前项目的 ID
  const currentProjectId = urlProjectId;
  return (
    <>
      <ListItem onClick={() => handleFunctionItemClick('allBots', `/${urlProjectId}/talk/bot`)} sx={{ paddingLeft: '20px' }} button >
        <ListItemIcon><SmartToyIcon /></ListItemIcon>
        <ListItemText primary="所有 Bot" />
      </ListItem>

      <Box sx={{mt: '10px', display: 'flex', alignItems: 'center', ml: '10px' }}>
        <Button sx={{ minWidth: '130px'}} variant="outlined" color="primary" onClick={handleCreateChat}>新聊天</Button>
        <Box sx={{ ml: '10px', cursor: 'pointer' }} onClick={quickStartClick}>
          <QuickStart projectId={currentProjectId} />
        </Box>
      </Box>
      <ChatList/>
    </>
  );
};

export default ChatTab;