import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import {
    List, ListItem, ListItemText
} from '@mui/material';
import { useProjectContext } from './Context';

const SqlTab = () => {
    const location = useLocation();
    const { urlProjectId, setSelectedTab } = useProjectContext();

    useEffect(() => {
        setSelectedTab(2);
    }, []);

    return (
        <>
           <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.default', color: 'text.primary', paddingTop: '0px' }}>
                <ListItem sx={{paddingLeft: '22px'}}button component={Link} to={`/${urlProjectId}/talk/sql-generation`} selected={location.pathname === `/${urlProjectId}/talk/sql-generation`} >
                    <ListItemText primary="速查" />
            </ListItem>
            <ListItem sx={{paddingLeft: '22px'}}button component={Link} to={`/${urlProjectId}/sql/log`} selected={location.pathname === `/${urlProjectId}/sql/log`} >
                    <ListItemText primary="日志" />
            </ListItem>
          </List>
        </>
    );
};

export default SqlTab;