import React, { useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import {
  Box, Typography, List, ListItem, ListItemText, ListItemIcon,
} from '@mui/material';
import { useProjectContext } from './Context';
import StorageIcon from '@mui/icons-material/Storage';
import RolesIcon from '@mui/icons-material/SupervisorAccount';
import ApiCallingIcon from '@mui/icons-material/Http';
import NoteAddIcon from '@mui/icons-material/NoteAdd';

const DataTab = () => {
  const { urlProjectId, setSelectedTab } = useProjectContext();
  const location = useLocation();

  useEffect(() => {
    setSelectedTab(0);
  }, []);

  return (
    <>
      <List sx={{ bgcolor: 'background.default', color: 'text.primary', paddingTop: '0px' }}>
        <Box>
          <ListItem
            component={Link}
            to={`/${urlProjectId}/data/data-source/create`}
            selected={location.pathname.startsWith(`/${urlProjectId}/data/data-source/create`)}
          >
            <ListItemIcon>
              <NoteAddIcon />
            </ListItemIcon>
            <ListItemText primary="添加数据" />
          </ListItem>
        </Box>
        <Box>
          <ListItem
            button
            component={Link}
            to={`/${urlProjectId}/data/data-source`}
            selected={location.pathname === `/${urlProjectId}/data/data-source`}
          >
            <ListItemIcon>
              <StorageIcon />
            </ListItemIcon>
            <ListItemText primary="全部数据" />
          </ListItem>      
          </Box>
        <Box>
          <ListItem
            button
            component={Link}
            to={`/${urlProjectId}/privilege/roles`}
            selected={location.pathname === `/${urlProjectId}/privilege/roles`}
          >
            <ListItemIcon>
              <RolesIcon />
            </ListItemIcon>
            <ListItemText primary="角色" />
          </ListItem>
        </Box>
        <Box>
          <ListItem
            button
            component={Link}
            to={`/${urlProjectId}/interaction/api-calling`}
            selected={location.pathname.startsWith(`/${urlProjectId}/interaction/api-calling`)}
          >
            <ListItemIcon>
              <ApiCallingIcon />
            </ListItemIcon>
            <ListItemText primary="API工具" />
          </ListItem>
        </Box>
      </List>
    </>
  );
};

export default DataTab;
