import React, { useState, useEffect, useCallback } from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const GuideOverlay = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  zIndex: theme.zIndex.modal + 1,
}));

const GuideBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  maxWidth: 300,
  textAlign: 'center',
  zIndex: theme.zIndex.modal + 3,
  pointerEvents: 'none',
}));

const HighlightBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  border: `2px solid ${theme.palette.primary.main}`,
  borderRadius: theme.shape.borderRadius,
  zIndex: theme.zIndex.modal + 2,
  cursor: 'pointer',
}));

const guideSteps = [
  {
    position: { top: '70px', left: '460px', width: '140px', height: '50px' },
    title: '进入聊天页面',
    description: '点击"聊天"标签进入聊天页面。'
  },
  {
    position: { top: '190px', left: '150px', width: '75px', height: '60px' },
    title: '快速开始聊天',
    description: '点击小火箭图标快速开始聊天。'
  }
];

const UserGuide = ({ onComplete }) => {
  const [currentStep, setCurrentStep] = useState(0);

  const handleHighlightClick = useCallback((e) => {
    e.stopPropagation();
    
    // 获取高亮区域下方的实际元素
    const { left, top } = e.target.getBoundingClientRect();
    const realElement = document.elementFromPoint(
      e.clientX - left + parseInt(guideSteps[currentStep].position.left),
      e.clientY - top + parseInt(guideSteps[currentStep].position.top)
    );

    // 触发实际元素的点击事件
    if (realElement) {
      realElement.click();
    }

    // 移动到下一步或完成引导
    if (currentStep < guideSteps.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      onComplete();
    }
  }, [currentStep, onComplete]);

  if (currentStep >= guideSteps.length) return null;

  const currentGuide = guideSteps[currentStep];

  return (
    <>
      <GuideOverlay />
      <HighlightBox
        style={currentGuide.position}
        onClick={handleHighlightClick}
      />
      <GuideBox style={{
        top: `${parseInt(currentGuide.position.top) + parseInt(currentGuide.position.height) + 10}px`,
        left: currentGuide.position.left,
      }}>
        <Typography variant="h6" gutterBottom>
          {currentGuide.title}
        </Typography>
        <Typography variant="body2">
          {currentGuide.description}
        </Typography>
      </GuideBox>
    </>
  );
};

export default UserGuide;