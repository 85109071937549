import React, { useState, useEffect } from 'react';
import { getRole, getRolePolicies, getRoleVariables } from '../api/askTableApi';
import { Box, Typography, CircularProgress, Paper, Divider, Tooltip, Grid, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

const RoleDetail = ({ roleId, onEdit, refresh }) => {
  const theme = useTheme();
  const [role, setRole] = useState(null);
  const [policies, setPolicies] = useState([]);
  const [variables, setVariables] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchRoleDetails = async () => {
      if (!roleId) return;

      try {
        const roleData = await getRole(roleId);
        setRole(roleData);

        const policiesData = await getRolePolicies(roleId);
        setPolicies(policiesData);

        // Uncomment when API is updated
        // const variablesData = await getRoleVariables(roleId);
        // setVariables(variablesData);

        setLoading(false);
      } catch (error) {
        console.error('Failed to fetch role details:', error);
      }
    };

    fetchRoleDetails();
  }, [roleId, refresh]);

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Box p={3} sx={{ fontSize: '14px' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>角色详情</Typography>
      </Box>

      <Divider sx={{ marginBottom: '16px' }} />

      <Box mb={2}>
        <Box display="flex">
          <Typography sx={{ fontWeight: 'bold', minWidth: '70px' }}>ID</Typography>
          <Typography>:</Typography>
          <Typography sx={{ marginLeft: '8px' }}>{role.id}</Typography>
        </Box>
        <Box display="flex">
          <Typography sx={{ fontWeight: 'bold', minWidth: '70px' }}>名称</Typography>
          <Typography>:</Typography>
          <Typography sx={{ marginLeft: '8px' }}>{role.name || '未命名角色'}</Typography>
        </Box>
        <Box display="flex">
          <Typography sx={{ fontWeight: 'bold', minWidth: '70px' }}>创建时间</Typography>
          <Typography>:</Typography>
          <Typography sx={{ marginLeft: '8px' }}>{new Date(role.created_at).toLocaleString()}</Typography>
        </Box>
        <Box display="flex">
          <Typography sx={{ fontWeight: 'bold', minWidth: '70px' }}>修改时间</Typography>
          <Typography>:</Typography>
          <Typography sx={{ marginLeft: '8px' }}>{new Date(role.modified_at).toLocaleString()}</Typography>
        </Box>
      </Box>

      <Divider sx={{ marginBottom: '16px' }} />

      <Box mb={2}>
        <Typography variant="subtitle1" fontWeight="bold" mb={1}>策略</Typography>
        <Grid container spacing={2}>
          {policies.map((policy) => (
            <Grid item xs={12} sm={6} md={4} key={policy.id}>
              <Tooltip 
                title={
                  <Box>
                    <Box display="flex">
                      <Typography variant="body2" sx={{ fontWeight: 'bold', minWidth: '30px' }}>ID</Typography>
                      <Typography variant="body2">:</Typography>
                      <Typography variant="body2" sx={{ marginLeft: '8px' }}>{policy.id}</Typography>
                    </Box>
                    <Box display="flex">
                      <Typography variant="body2" sx={{ fontWeight: 'bold', minWidth: '30px' }}>时间</Typography>
                      <Typography variant="body2">:</Typography>
                      <Typography variant="body2" sx={{ marginLeft: '8px' }}>{new Date(policy.created_at).toLocaleString()}</Typography>
                    </Box>
                  </Box>
                }
              >
                <Paper sx={{ padding: '8px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {policy.permission === 'allow' ? (
                      <CheckCircleIcon color="success" sx={{ mr: 1 }} />
                    ) : (
                      <CancelIcon color="error" sx={{ mr: 1 }} />
                    )}
                    <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }}>{policy.name}</Typography>
                  </Box>
                </Paper>
              </Tooltip>
            </Grid>
          ))}
        </Grid>
      </Box>

      <Divider sx={{ marginBottom: '16px' }} />

      <Box mb={2}>
        <Typography variant="subtitle1" fontWeight="bold">变量</Typography>
        <Box mt={1}>
          <pre style={{ color: theme.palette.text.primary, background: theme.palette.background.default, padding: '10px', borderRadius: '8px', fontSize: '12px' }}>
            {JSON.stringify(variables, null, 2)}
          </pre>
        </Box>
      </Box>
    </Box>
  );
};

export default RoleDetail;