import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Typography,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Snackbar,
  Alert,
  Paper,
  CircularProgress,
  TablePagination,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import PolicyDetail from '../components/PolicyDetail';
import PolicyDialog from '../components/PolicyDialog';
import { getPolicies, deletePolicy } from '../api/askTableApi';
import { useTheme } from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info'; // 新增用于详细信息的图标
import { formatDistanceToNow } from 'date-fns';
import { zhCN } from 'date-fns/locale';


const Policies = () => {
  const theme = useTheme();
  const [policies, setPolicies] = useState([]);
  const [selectedPolicy, setSelectedPolicy] = useState(null);
  const [policyDialogOpen, setPolicyDialogOpen] = useState(false);
  const [editPolicy, setEditPolicy] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [refresh, setRefresh] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5); // 每页显示6行
  const [detailDialogOpen, setDetailDialogOpen] = useState(false); // 新增状态控制详细信息弹窗

  const fetchPolicies = async () => {
    try {
      const policiesData = await getPolicies();
      setPolicies(policiesData.data);
      setLoading(false);
    } catch (error) {
      console.error('Failed to fetch policies:', error);
      setError(error.message || 'Failed to fetch policies');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPolicies();
  }, [refresh]);

  const handleEditPolicy = (policyId) => {
    const policy = policies.find((p) => p.id === policyId);
    setEditPolicy(policy);
    setPolicyDialogOpen(true);
  };

  const handleDeletePolicy = async (policyId) => {
    if (window.confirm("确定要删除这个策略吗？")) {
      try {
        await deletePolicy(policyId);
        setPolicies(policies.filter(policy => policy.id !== policyId));
        setSnackbarMessage('策略删除成功');
        setSnackbarOpen(true);
      } catch (error) {
        console.error('删除策略失败:', error);
        setSnackbarMessage('删除策略失败');
        setSnackbarOpen(true);
      }
    }
  };

  const handleSavePolicy = () => {
    setPolicyDialogOpen(false);
    setRefresh(!refresh); // 刷新列表
  };

  const handleCreatePolicy = () => {
    setEditPolicy(null); // 重置 editPolicy 状态
    setPolicyDialogOpen(true); // 打开对话框
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
    setError('');
  };

  const handleDetailClick = (policyId) => {
    setSelectedPolicy(policyId);
    setDetailDialogOpen(true); // 打开详细信息弹窗
  };

  const handleDetailDialogClose = () => {
    setDetailDialogOpen(false); // 关闭详细信息弹窗
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box >
      <Box className="guide-section">
        <Typography gutterBottom>
          创建策略（policy）来设置数据的可见性和访问权限
        </Typography>
      </Box>
      <Button variant="outlined" color="primary" onClick={handleCreatePolicy}>
        创建策略
      </Button>
      {loading ? (
        <CircularProgress />
      ) : (
        <TableContainer component={Paper} sx={{ marginTop: 2 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>名字</TableCell>
                <TableCell>类型</TableCell>
                <TableCell>创建时间</TableCell>
                <TableCell>修改时间</TableCell>
                <TableCell>详细信息</TableCell> {/* 新增的表头 */}
                <TableCell>操作</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {policies.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((policy) => (
                <TableRow key={policy.id}  style={{height: '70px'}}>
                  <TableCell>{policy.id}</TableCell>
                  <TableCell>{policy.name}</TableCell>
                  <TableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      {policy.permission === 'allow' ? (
                        <>
                          <CheckCircleIcon fontSize="small" color="primary" />
                          <span>允许</span>
                        </>
                      ) : (
                        <>
                          <CancelIcon fontSize="small" color="error" />
                          <span>不允许</span>
                        </>
                      )}
                    </Box>
                  </TableCell>
                  <TableCell>{formatDistanceToNow(new Date(policy.created_at), { addSuffix: true, locale: zhCN })}</TableCell>
                  <TableCell>{formatDistanceToNow(new Date(policy.modified_at), { addSuffix: true, locale: zhCN })}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleDetailClick(policy.id)}>
                      <InfoIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleEditPolicy(policy.id)}>
                      <EditIcon  />
                    </IconButton>
                    <IconButton onClick={() => handleDeletePolicy(policy.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              {Array.from(new Array(rowsPerPage - policies.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).length)).map((_, index) => (
                <TableRow key={`empty-${index}`}>
                  <TableCell colSpan={7} />
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[6]} // 固定每页6行
            component="div"
            count={policies.length} // 总条目数
            rowsPerPage={rowsPerPage} // 每页显示的条目数
            page={page} // 当前页码
            onPageChange={handleChangePage} // 页码改变的处理函数
            onRowsPerPageChange={handleChangeRowsPerPage} // 每页显示行数改变的处理函数
            labelDisplayedRows={({ from, to, count }) => `页码：${page + 1} / ${Math.ceil(count / rowsPerPage)}`}
            className="pagination-container"
          />
        </TableContainer>
      )}
      <PolicyDialog
        open={policyDialogOpen}
        onClose={() => setPolicyDialogOpen(false)}
        onSave={handleSavePolicy}
        editPolicy={editPolicy}
      />

      {/* 详细信息显示对话框 */}
      <Dialog open={detailDialogOpen} onClose={handleDetailDialogClose}>
        {selectedPolicy && <PolicyDetail policyId={selectedPolicy} refresh={refresh} />}
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarMessage.includes('失败') ? "error" : "success"} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Policies;
