// themeLight.js
import { createTheme } from '@mui/material/styles';
import { keyframes } from '@mui/system';

// 定义关键帧动画
const expandAndBounce = keyframes`
  0% {
    height: 0;
    top: 50%;
  }
  50% {
    height: 60%;
    top: 20%;
  }
  100% {
    height: 50%;
    top: 25%;
  }
`;
const themeLight = createTheme({
  palette: {
    primary: {
      main: '#8AD8BC', // 按钮主题色
    },
    secondary: {
      main: '#8AD8BC', // 选中选项背景色
    },
    background: {
      default: '#FFFFFF', // 左侧目录主题色（浅灰色）
      paper: '#FFFFFF', // 顶部主题色（白色）
    },
    text: {
      primary: '#333333', // 黑色 （历史记录时间）
      secondary: '#FFFFFF', // 白色 （没找到是什么）
      third: '#333333', // 黑色（历史记录标题）
      four: 'transparent',  // 透明
      title:'#0C101D',    //黑色标题 
      subtitle:'#C2C2C2'  //灰色副标题 
    },
    action: {
      hover: '#E0E0E0', // 选项未选中时浮动效果
      selected: '#8AD8BC', // 选中选项 hover 时
    },
    grey: {
      500: '#E0E0E0', // 按钮未选中时未 hover 效果
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '9999px', // 完全圆角
          transition: 'all 0.3s ease',
          fontWeight: 600, // 加粗文字
          padding: '8px 24px', // 调整内边距
        },
        outlined: {
          borderColor: '#006A4C', // 深绿色边框
          borderWidth: '2px',
          color: '#006A4C', // 深绿色文字
          backgroundColor: 'transparent', // 透明背景
          boxShadow: '0 0 0 0 rgba(0, 94, 60, 0)', // 初始状态无阴影
          '&:hover': {
           // backgroundColor: '#D9F5F0', // 悬停时的薄荷绿背景
            borderColor: '#006A4C', // 保持深绿色边框
            color: '#006A4C', // 保持深绿色文字
            borderWidth: '2px', // 确保悬停时边框宽度不变
            boxShadow: '0 4px 0 0 #006A4C', // 底部阴影，看起来像是边框扩展
            transform: 'translateY(-2px)', // 轻微上移，增强悬浮感
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: 'inherit', // 取消默认颜色
          '&:hover': {
            color: '#8AD8BC', // 悬浮时变为 primary
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: '#333333', // 设置 helperText 的全局颜色
          '&.Mui-error': {
            color: '#FF6F61', // 错误状态下的 helperText 颜色
          },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          padding: '10px 5px', // 增加内边距
          backgroundColor: 'transparent', // 列表背景颜色
          color: '#006A4C', // 列表字体颜色
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          position: 'relative',
          borderRadius: '16px', // 设置圆角
          padding: '10px 16px', // 增加内边距
          marginTop: "10px",
          transition: 'background-color 0.3s', // 取消默认的点击动效，改为背景色过渡
          '&:hover': {
            backgroundColor: '#ebf7ef',
            '& .MuiListItemText-primary': {
              color: '#006A4C',
              fontWeight: 'bold',
            },
          },
          '&.Mui-selected': {
            backgroundColor: '#ebf7ef',
            '& .MuiListItemText-primary': {
              color: '#006A4C',
              fontWeight: 'bold',
            },
            '& .MuiListItemIcon-root': {
              color: '#006A4C', // 选中时Icon颜色
              '& svg': {
                fill: '#006A4C', // 填充深绿色
              },
            },
          },
        '& .MuiListItemIcon-root': {
          color: '#006A4C', 
        },

        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontSize: '0.9rem',
          color: '#006A4C', // 未选中时文本颜色
        },
        secondary: {
          fontSize: '0.7rem',
          color: '#8AD8BC', // Secondary 文本颜色
        },
      },
    },
    MuiOutlinedInput: { // 这里设置全局的输入框样式
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#e0e0e0', // 输入框边框颜色未 hover 效果
            transition: 'border-color 0.3s', // 输入框边框颜色切换时间 0.3 秒
            borderWidth: '1.8px'
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#8AD8BC', // 输入框边框颜色 hover 效果
          },
        },
        input: {
          color: '#333333', // 输入框内文本颜色
          '&::placeholder': {
            color: '#8AD8BC', // 输入框默认的文字颜色
          },
        },
      },
    },
    MuiInputLabel: { // 高亮：设置 InputLabel 组件的样式
      styleOverrides: {
        root: {
          color: '#65cca7', // 设置标签的全局颜色为绿色
          '&.Mui-focused': {
            color: '#65cca7', // 标签在获得焦点时的颜色
          },
          backgroundColor: 'transparent', // 确保标签背景颜色为透明
          '&.Mui-focused': {
            backgroundColor: 'transparent', // 聚焦时背景颜色为透明
          },
          '&.Mui-selected': {
            backgroundColor: 'transparent', // 选中时背景颜色为透明
          },
          '&.Mui-focused.Mui-selected': {
            backgroundColor: 'transparent', // 聚焦且选中时背景颜色为透明
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderColor: '#000000', // 输入框边框颜色未 hover 效果
          transition: 'border-color 0.3s', // 输入框边框颜色切换时间 0.3 秒
          '&:hover': {
            borderColor: '#006A4C', // 输入框边框颜色 hover 效果
          },
        },
        input: {
          color: '#333333', // 输入框内文本颜色
          '&::placeholder': {
            color: '#8AD8BC', // 输入框默认的文字颜色
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#FFFFFF', // 卡片对应背景
          color: '#333333', // 卡片普通文字颜色
          '& .MuiTypography-h5': {
            color: '#333333', // 卡片标题颜色
          },
        },
      },
    },
    MuiTooltip: {
      defaultProps: {
        placement: 'top',
        disableInteractive: true,
        arrow: true,
      },
      styleOverrides: {
        tooltip: {
          backgroundColor: '#ffffff',
          color: '#333333',
          fontSize: '0.7rem',
          border: '2px solid #8AD8BC',
          borderRadius: '8px',
          padding: '8px',
          boxShadow: '0 4px 6px rgba(0, 168, 107, 0.1)',
          maxWidth: '400px',  // 控制最大宽度
          whiteSpace: 'normal',  // 允许文本换行
          wordWrap: 'break-word',  // 确保长单词也会换行
        },
        arrow: {
          color: '#8AD8BC',
        },
      },
    },
 MuiTable: {
    styleOverrides: {
      root: {
        width: '100%',
        borderCollapse: 'separate',
        borderSpacing: 0,
        borderRadius: '8px',
        overflow: 'hidden',
        boxShadow: '0 4px 6px rgba(0, 94, 60, 0.1)',
      },
    },
  },
  MuiTableContainer: {
    styleOverrides: {
      root: {
        height: '470px', // 保持总高度不变
        overflow: 'hidden', // 防止出现滚动条
      },
    },
  },
  MuiTableHead: {
    styleOverrides: {
      root: {
        backgroundColor: '#006A4C',
        height: '60px', // 保持表头高度不变
        '& .MuiTableCell-root': {
          color: '#FFFFFF',
          fontWeight: 600,
          textTransform: 'uppercase',
          fontSize: '0.875rem',
        },
      },
    },
  },
  MuiTableBody: {
    styleOverrides: {
      root: {
        height: '350px', // 增加表体高度以填充可用空间
        
        '& .MuiTableRow-root': {
          transition: 'background-color 0.3s ease',
          '&:hover': {
            backgroundColor: '#e6f7f2 !important',
          },
        },
        '& .MuiTableRow-root:nth-of-type(odd)': {
          backgroundColor: '#f3fbf8',
        },
        '& .MuiTableRow-root:nth-of-type(even)': {
          backgroundColor: '#FFFFFF',
        },
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        padding: '12px',
        paddingLeft: '40px',
        borderBottom: '1px solid #dbf3ea',
        fontSize: '0.875rem',
        '&:first-of-type': {
          paddingLeft: '24px',
        },
      },
      head: {
        borderBottom: 'none',
      },
    },
  },
  MuiTablePagination: {
    styleOverrides: {
      root: {
        height: '60px', // 保持分页区域高度不变
        backgroundColor: '#FFFFFF',
        borderTop: '1px solid #dbf3ea',
      },
      toolbar: {
        paddingLeft: '16px',
        paddingRight: '16px',
      },
      actions: {
        marginLeft: 'auto',
      },
      selectIcon: {
        color: '#006A4C',
      },
    },
  },
  MuiIconButton: {
    styleOverrides: {
      root: {
        color: '#006A4C',
        '&:hover': {
          backgroundColor: 'rgba(0, 94, 60, 0.1)',
        },
        '&.Mui-disabled': {
          color: 'rgba(0, 94, 60, 0.3)',
        },
      },
    },
    },
  },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: '12px',
          boxShadow: '0 8px 32px rgba(0, 0, 0, 0.08)',
          padding: '24px',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: '1.5rem',  // 对应于 h5 的大小
          fontWeight: 'bold',  // 添加粗体
          color: '#333',
          padding: '0 0 16px 0',
          borderBottom: '1px solid #eaeaea',
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: '24px 0',
          color: '#555',
          fontSize: '1rem',
          lineHeight: 1.6,
        },
      },
    },
  typography: {
    fontFamily: 'Roboto, Arial, sans-serif', // 字体格式
  },
});

export default themeLight;
