import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Typography,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Checkbox,
  Grid,
  Pagination,
  Divider,
  TextField,
  Snackbar,
  Alert
} from '@mui/material';
import { getPolicies, createRole, updateRole } from '../api/askTableApi';
import AllowIcon from '@mui/icons-material/CheckCircleOutline';
import DenyIcon from '@mui/icons-material/HighlightOff';

const RoleDialog = ({ open, onClose, editRole, onSave }) => {
  const [policies, setPolicies] = useState([]);
  const [selectedPolicies, setSelectedPolicies] = useState([]);
  const [currentPolicy, setCurrentPolicy] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [roleName, setRoleName] = useState('New Role');

  useEffect(() => {
    const fetchPolicies = async () => {
      try {
        const policiesData = await getPolicies(10, page);
        setPolicies(policiesData.data);
        setTotalPages(policiesData.total_page);
        setLoading(false);
      } catch (error) {
        setError(error.message || 'Failed to fetch policies');
        setLoading(false);
      }
    };

    if (open) {
      fetchPolicies();
    }
  }, [page, open]);

  useEffect(() => {
    if (open && editRole) {
      setRoleName(editRole.name);
      setSelectedPolicies(editRole.policy_ids || []);
    } else {
      setRoleName('New Role');
      setSelectedPolicies([]);
    }
  }, [open, editRole]);

  const handlePolicyToggle = (policyId) => {
    setSelectedPolicies((prevSelected) => {
      if (prevSelected.includes(policyId)) {
        return prevSelected.filter((id) => id !== policyId);
      } else {
        return [...prevSelected, policyId];
      }
    });
  };

  const handleSave = async () => {
    const roleData = {
      name: roleName,
      policy_ids: selectedPolicies
    };

    try {
      if (editRole) {
        await updateRole(editRole.id, roleData);
      } else {
        await createRole(roleData);
      }
      onSave();
      onClose();
    } catch (error) {
      setError(error.message || 'Failed to save role');
    }
  };

  const handleSnackbarClose = () => {
    setError('');
  };

  const handlePolicyClick = (policy) => {
    setCurrentPolicy(policy);
  };

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle variant="h5" sx={{ mt: 1.5 , fontWeight: 'bold' }}>{editRole ? '编辑角色' : '创建角色'}</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label="角色名称"
            value={roleName}
            onChange={(e) => setRoleName(e.target.value)}
            margin="normal"
            helperText="由小写字母、数字和下划线组成，不超过64个字符"
          />
          {loading ? (
            <CircularProgress />
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={4.5}>
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>选择策略</Typography>
                <List sx={{ padding: 0 }}>
                  {policies.map((policy) => (
                    <ListItem
                      key={policy.id}
                      button
                      onClick={() => handlePolicyClick(policy)}
                      selected={currentPolicy && currentPolicy.id === policy.id}
                      sx={{
                        padding: '8px 0', // 调整间距
                        display: 'flex',
                        alignItems: 'center',
                        backgroundColor: selectedPolicies.includes(policy.id) ? 'rgba(0, 0, 0, 0)' : 'transparent', // 背景色
                        '&.Mui-selected': {
                          backgroundColor: '#F5F5F5', // 选中时的淡绿色背景色
                          '&:hover': {
                            backgroundColor: '#F5F5F5', // 选中并悬停时颜色稍微加深
                          },
                        },
                        '&:hover': {
                          backgroundColor: '#F5F5F5', // 选中并悬停时颜色稍微加深
                        },
                      }}
                    >
                      {/* 第一列: 选择框 */}
                      <Checkbox
                        checked={selectedPolicies.includes(policy.id)}
                        onChange={() => handlePolicyToggle(policy.id)}
                        sx={{
                          padding: 0,
                          marginRight: 2,
                          '& .MuiSvgIcon-root': {
                            color: selectedPolicies.includes(policy.id) ? 'default' : 'lightgray', // 未选中时灰色
                          },
                          '&.Mui-checked .MuiSvgIcon-root': {
                            color: 'default', // 选中时使用默认颜色
                          },
                        }}
                      />


                      {/* 第二列: 允许或不允许的图标 */}
                      <Box
                        sx={{
                          width: 24,
                          height: 24,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          marginRight: 2, // 图标与文字的间距
                        }}
                      >
                        {policy.permission === 'allow' ? (
                          <AllowIcon color="primary" />
                        ) : (
                          <DenyIcon color="error" />
                        )}
                      </Box>

                      {/* 第三列: 策略名称 */}
                      <ListItemText
                        primary={policy.name}
                        sx={{ fontSize: '1rem' }}
                      />
                    </ListItem>
                  ))}
                </List>
                <Pagination count={totalPages} page={page} onChange={(e, value) => setPage(value)} />
              </Grid>

              <Grid item xs={6}>
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>策略详情</Typography>
                {currentPolicy ? (
                  <Box>
                    <Box display="flex">
                      <Typography variant="body2" color="textPrimary" sx={{ fontWeight: 'bold', minWidth: '60px' }}>名字</Typography>
                      <Typography variant="body2">：</Typography>
                      <Typography variant="body2" color="textPrimary">{currentPolicy.name}</Typography>
                    </Box>

                    <Box display="flex">
                      <Typography variant="body2" color="textPrimary" sx={{ fontWeight: 'bold', minWidth: '60px' }}>类型</Typography>
                      <Typography variant="body2">：</Typography>
                      <Typography variant="body2" color="textPrimary">
                        {currentPolicy.permission === 'allow' ? '允许策略' : '不允许策略'}
                      </Typography>
                    </Box>

                    <Box display="flex">
                      <Typography variant="body2" color="textPrimary" sx={{ fontWeight: 'bold', minWidth: '60px' }}>ID</Typography>
                      <Typography variant="body2">：</Typography>
                      <Typography variant="body2" color="textPrimary">{currentPolicy.id}</Typography>
                    </Box>

                    <Box display="flex">
                      <Typography variant="body2" color="textPrimary" sx={{ fontWeight: 'bold', minWidth: '60px' }}>创建时间</Typography>
                      <Typography variant="body2">：</Typography>
                      <Typography variant="body2" color="textPrimary">
                        {new Date(currentPolicy.created_at).toLocaleString()}
                      </Typography>
                    </Box>

                    <Box display="flex">
                      <Typography variant="body2" color="textPrimary" sx={{ fontWeight: 'bold', minWidth: '60px' }}>数据源</Typography>
                      <Typography variant="body2">：</Typography>
                      <Typography
                        variant="body2"
                        color="textPrimary"
                        sx={{ wordWrap: 'break-word', whiteSpace: 'normal', width: '100%' }}
                      >
                        {currentPolicy.dataset_config.datasource_ids}
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        backgroundColor: '#f5f5f5', // 背景颜色
                        padding: '8px',
                        borderRadius: '4px',
                        marginTop: '8px',
                        overflowX: 'auto',
                        whiteSpace: 'pre-wrap',
                        wordWrap: 'break-word',
                      }}
                    >
                      <pre style={{ margin: 0 }}>
                        {JSON.stringify(currentPolicy.dataset_config, null, 2)}
                      </pre>
                    </Box>
                  </Box>
                ) : (
                  <Typography variant="body2">
                    点击策略查看详细信息
                  </Typography>
                )}
              </Grid>


            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>取消</Button>
          <Button onClick={handleSave} color="primary">保存</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>
    </>
  );
};

export default RoleDialog;
