import React, { useState, useEffect } from 'react';
import { useTable } from 'react-table';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Box, Card, CardContent, TextField, CircularProgress } from '@mui/material';
import { getDatasource, getDatasourceMeta, updateDatasource, updateDatasourceMeta } from '../api/askTableApi'; // 假设接口文件路径
import AccessConfigCard from './AccessConfigCard';

const DataSourceDetail = ({ open, onClose, datasourceId }) => {
  const [metaData, setMetaData] = useState(null);
  const [dataSource, setDataSource] = useState(null);
  const [loading, setLoading] = useState(true);
  const [refreshing, setRefreshing] = useState(false);
  const [renaming, setRenaming] = useState(false);
  const [updating, setUpdating] = useState(false); 
  const [newName, setNewName] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [dataSourceResponse, metaResponse] = await Promise.all([
          getDatasource(datasourceId),
          getDatasourceMeta(datasourceId),
        ]);
        setDataSource(dataSourceResponse);
        setMetaData(metaResponse);
        setNewName(dataSourceResponse.name || '');
      } catch (error) {
        console.error('Failed to fetch datasource details:', error);
      } finally {
        setLoading(false);
      }
    };

    if (datasourceId) {
      fetchData();
    }
  }, [datasourceId]);

  const handleRefreshMeta = async () => {
    setRefreshing(true);
    try {
      await updateDatasourceMeta(datasourceId);
      const updatedMetaResponse = await getDatasourceMeta(datasourceId);
      setMetaData(updatedMetaResponse);
    } catch (error) {
      console.error('Failed to update datasource meta:', error);
    } finally {
      setRefreshing(false);
    }
  };

  const handleRenameClick = () => {
    setRenaming(true);
  };

  const handleUpdateName = async () => {
    setUpdating(true);
    try {
      await updateDatasource(datasourceId, { name: newName });
      setDataSource((prev) => ({ ...prev, name: newName }));
      setRenaming(false);
    } catch (error) {
      console.error('Failed to update datasource name:', error);
    } finally {
      setUpdating(false);
    }
  };

  const columns = React.useMemo(
    () => {
      const commonColumns = [
        {
          Header: '字段名称',
          accessor: 'name',
          headerStyle: { textAlign: 'left' },
        },
        {
          Header: '描述',
          accessor: 'curr_desc',
          headerStyle: { textAlign: 'left' },
        },
      ];

      if (dataSource && ['mysql', 'tidb', 'oceanbase', 'postgresql'].includes(dataSource.engine)) {
        return [
          ...commonColumns,
          {
            Header: '数据类型',
            accessor: 'data_type',
            headerStyle: { textAlign: 'left' },
          },
        ];
      }

      return commonColumns;
    },
    [dataSource]
  );

  const data = React.useMemo(
    () =>
      metaData ? metaData.schemas.flatMap((schema) =>
        schema.tables.flatMap((table) =>
          table.fields.map((field) => ({
            ...field,
            schemaName: schema.name,
            tableName: table.name,
          }))
        )
      ) : [],
    [metaData]
  );

  const tableInstance = useTable({ columns, data });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

  // if (loading) {
  //   return <div>Loading...</div>;
  // }

  const renderTable = (table) => (
    <Box key={table.name} ml={2} mb={2}>
      <Typography variant="subtitle1" sx={{ fontSize: '1.2em', fontWeight: ['mysql', 'tidb', 'oceanbase', 'postgresql'].includes(dataSource.engine) ? 'bold' : 'normal' }}>
        {['mysql', 'tidb', 'postgresql', 'oceanbase'].includes(dataSource.engine) ? `表：${table.name}` : table.name}
      </Typography>
      <table {...getTableProps()} style={{ width: '100%', marginBottom: '16px' }}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps({ style: column.headerStyle })}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </Box>
  );

  const renderEngineType = (engine) => {
    switch (engine) {
      case 'mysql':
        return 'MySQL';
      case 'postgresql':
        return 'PostgreSQL';
      case 'tidb':
        return 'TiDB';
      case 'oceanbase':
        return 'OceanBase';
      case 'excel':
        return 'Excel';
      case 'csv':
        return 'CSV';
      default:
        return engine;
    }
  };

const renderSchemaTable = (schema) => (
    <Box key={schema.name} mb={3}>
   <Typography variant="h6" sx={{ fontSize: '1.4em' }}>
      数据库：{schema.name}
    </Typography>
    <Typography variant="body2" sx={{ fontSize: '0.9em' }}>
      {schema.curr_desc}
    </Typography>
      <Box component="table" width="100%" sx={{ borderCollapse: 'collapse'}}>
        <Box component="thead" sx={{ borderBottom: '1px solid', fontWeight: 'bold' }}>
          <Box component="tr">
            <Box component="th" sx={{ textAlign: 'left', padding: 1 }}>表</Box>
            <Box component="th" sx={{ textAlign: 'left', padding: 1 }}>描述</Box>
            <Box component="th" sx={{ textAlign: 'left', padding: 1 }}>字段（AI分析完成/总数）</Box>
          </Box>
        </Box>
        <Box component="tbody">
          {schema.tables.map((table) => {
            const analysedFields = table.fields.filter(f => f.curr_desc).length;
            const totalFields = table.fields.length;
            return (
              <Box component="tr" key={table.name}>
                <Box component="td" sx={{ padding: 1 }}>{table.name}</Box>
                <Box component="td" sx={{ padding: 1 }}>{table.curr_desc}</Box>
                <Box component="td" sx={{ padding: 1 }}>{`${analysedFields}/${totalFields}`}</Box>
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );

  return (
      <Dialog open={open} onClose={onClose} maxWidth="md" >
        {dataSource && (
          <>
            <DialogTitle  variant="h5" sx={{ mt: 1.5 , fontWeight: 'bold' }}>数据源详情</DialogTitle>
            <DialogContent>
              {/* 数据源基本信息 */}
              <Card variant="outlined" sx={{ mb: 3 }}>
                <CardContent>
                  <Typography variant="h6" gutterBottom>基本信息</Typography>
                  <Box display="flex" alignItems="center">
                    {renaming ? (
                      <>
                        <TextField
                          label="数据源名称"
                          value={newName}
                          onChange={(e) => setNewName(e.target.value)}
                          fullWidth
                        />
                        <Button
                          onClick={handleUpdateName}
                          color="primary"
                          variant="outlined"
                          disabled={updating}
                          sx={{ ml: 2 }}
                        >
                          {updating ? <CircularProgress size={24} /> : '提交'}
                        </Button>
                      </>
                    ) : (
                      <>
                        <Typography variant="body1"><strong>数据源名称:</strong> {dataSource.name || '未命名数据源'}</Typography>
                        <Button onClick={handleRenameClick} color="primary" variant="outlined" sx={{ ml: 2 }}>
                          改名
                        </Button>
                      </>
                    )}
                  </Box>
                  <Typography variant="body1"><strong>引擎类型:</strong> {renderEngineType(dataSource.engine)}</Typography>
                  <Typography variant="body1"><strong>创建时间:</strong> {new Date(dataSource.created_at).toLocaleString()}</Typography>
                  {dataSource.meta_status === 'failed' && (
                    <Typography variant="body1" color="error"><strong>元数据状态:</strong> {dataSource.meta_status}</Typography>
                  )}
                  {dataSource.meta_error && (
                    <Typography variant="body1" color="error"><strong>元数据错误:</strong> {dataSource.meta_error}</Typography>
                  )}

                </CardContent>
              </Card>

              {/* 访问信息 */}
              <AccessConfigCard dataSource={dataSource} />
              <Box mb={2}>
                    <Button onClick={handleRefreshMeta} color="primary" variant="outlined" disabled={refreshing}>
                      {refreshing ? <CircularProgress size={24} /> : '刷新元数据'}
                    </Button>
              </Box>
              {/* 数据源表结构 */}
              <Card variant="outlined" sx={{ mb: 3 }}>
              <CardContent>
              {metaData && ['mysql', 'tidb', 'postgresql', 'oceanbase'].includes(dataSource.engine) && metaData.schemas.map(renderSchemaTable)}
              {metaData && dataSource.engine === 'excel' && metaData.schemas.length > 0 && (
                <Box mb={3}>
                  <Typography variant="h6" sx={{ fontSize: '1.4em' }}>Excel工作表</Typography>
                  {metaData.schemas[0].tables.map(renderTable)}
                </Box>
              )}
              {metaData && dataSource.engine === 'csv' && metaData.schemas.length > 0 && metaData.schemas[0].tables.length > 0 && (
                <Box mb={3}>
                  <Typography variant="h6" sx={{ fontSize: '1.4em' }}>{metaData.schemas[0].tables[0].name}</Typography>
                  {renderTable(metaData.schemas[0].tables[0])}
                </Box>
              )}
              </CardContent>
              </Card>
            </DialogContent>
          </>
        )}
        <DialogActions>
          <Button onClick={onClose} color="primary">关闭</Button>
        </DialogActions>
      </Dialog>
    );
  };


export default DataSourceDetail;
