import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, IconButton, Box, Grid, Typography } from '@mui/material';
import { createExtAPI, updateExtAPI } from '../../api/askTableApi';
import { Delete as DeleteIcon, Add as AddIcon } from '@mui/icons-material';

const ExtAPIDialog = ({ open, onClose, onSave, extAPI }) => {
  const [name, setName] = useState('');
  const [baseURL, setBaseURL] = useState('');
  const [headers, setHeaders] = useState([{ key: '', value: '' }]);

  useEffect(() => {
    if (extAPI) {
      setName(extAPI.name);
      setBaseURL(extAPI.base_url);
      setHeaders(extAPI.headers ? Object.entries(extAPI.headers).map(([key, value]) => ({ key, value })) : [{ key: '', value: '' }]);
    } else {
      setName('');
      setBaseURL('');
      setHeaders([{ key: '', value: '' }]);
    }
  }, [extAPI]);

  const handleAddHeader = () => {
    setHeaders([...headers, { key: '', value: '' }]);
  };

  const handleRemoveHeader = (index) => {
    setHeaders(headers.filter((_, i) => i !== index));
  };

  const handleHeaderChange = (index, key, value) => {
    const newHeaders = headers.slice();
    newHeaders[index][key] = value;
    setHeaders(newHeaders);
  };

  const handleSave = async () => {
    const headersObject = headers.reduce((acc, { key, value }) => {
      if (key) acc[key] = value;
      return acc;
    }, {});
    const payload = { name, base_url: baseURL, headers: Object.keys(headersObject).length > 0 ? headersObject : null };
    try {
      const savedExtAPI = extAPI ? await updateExtAPI(extAPI.id, payload) : await createExtAPI(payload);
      onSave(savedExtAPI);
    } catch (error) {
      console.error('Failed to save API 端点', error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{extAPI ? '编辑 API 端点' : '创建 API 端点'}</DialogTitle>
      <DialogContent>
        <TextField label="名称" fullWidth margin="dense" value={name} onChange={(e) => setName(e.target.value)} />
        <TextField
          label="根 URL" fullWidth margin="dense" value={baseURL} onChange={(e) => setBaseURL(e.target.value)}
          helperText="比如：http://example.com/api/v1"
        />

        <Typography variant="h6" gutterBottom sx={{ marginTop: '16px' }}>HTTP 头</Typography>
        {headers.map((header, index) => (
          <Grid container spacing={2} key={index} alignItems="center">
            <Grid item xs={5}>
              <TextField label="键" fullWidth margin="dense" value={header.key} onChange={(e) => handleHeaderChange(index, 'key', e.target.value)} />
            </Grid>
            <Grid item xs={5}>
              <TextField label="值" fullWidth margin="dense" value={header.value} onChange={(e) => handleHeaderChange(index, 'value', e.target.value)} />
            </Grid>
            <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'center' }}>
              <IconButton onClick={() => handleRemoveHeader(index)}>
                <DeleteIcon color="error" />
              </IconButton>
            </Grid>
          </Grid>
        ))}
        <Box sx={{ textAlign: 'center', marginTop: '16px' }}>
          <Button startIcon={<AddIcon />} onClick={handleAddHeader}>添加 Header 头</Button>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>取消</Button>
        <Button onClick={handleSave} color="primary">保存</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ExtAPIDialog;
