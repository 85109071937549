import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box, Typography, Menu, MenuItem, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button
} from '@mui/material';
import { Add as AddIcon, Folder as FolderIcon, Settings as SettingsIcon, ArrowDropDown as ArrowDropDownIcon } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import { useAppContext1, useProjectContext } from './Context';
import { createProject, changeProject, getProjects, getUsers,getProject} from '../api/projectApi';

const ProjectSelector = () => {
  const [projectOpen, setProjectOpen] = useState(false);
  const [searchPOpen, setSearchPOpen] = useState(false);
  const [projectSid, setProjectSid] = useState('');


  const [anchorEl, setAnchorEl] = useState(null);
  const [projectName, setProjectName] = useState('');
  const navigate = useNavigate();
  const [menuItems, setMenuItems] = useState([]);

  const {urlProjectId, projectData, setProjectData, custom_feedback} = useProjectContext();
  const {user} = useAppContext1();

  useEffect(() => {
    const fetchProjects = async () => {
      if (urlProjectId) { 
        const data = await getProject(urlProjectId);
        setProjectData(data);
      }
    };
    fetchProjects();
  }, [urlProjectId]);

  useEffect(() => {
    const fetchProjects = async () => {
      const data = await getProjects();
      setMenuItems(data);
    };

    fetchProjects();
  }, []);

  useEffect(() => {
    const updateMenuItems = () => {
      setMenuItems(prevItems => {
        const exists = prevItems.some(project => project.project_id === projectData.project_id);
        return exists ? prevItems : [projectData, ...prevItems];
      });
    };

    if (projectData.project_id) {
      updateMenuItems();
    }
  }, [projectData]);

  const handleChange = async (project) => {
    // 处理切换项目逻辑, 切换路由
    navigate(`/${project.project_id}/data/data-source`);
  };

  const handleClickOpen = () => {
    const box = document.getElementById('chatBox');
    if (box) {
      box.remove();
    }
    setProjectOpen(true);
    setProjectName('');
  };

  const handleClickSearch = () => {
    const box = document.getElementById('chatBox');
    if (box) {
      box.remove();
    }
    // 打开新建项目弹框
    setSearchPOpen(true);
    setProjectSid('')
  };
  const SearchPClose = () => {
    setSearchPOpen(false);
  };
  const ProjectSeacrh = async () => {
    navigate(`/${projectSid}/data/data-source`);
    setSearchPOpen(false);
  };

  const handleClickSetting = () => {
    navigate(`/${urlProjectId}/setting`);
  };

  const handleProjectClose = () => {
    setProjectOpen(false);
  };

  const handleProjectAdd = async () => {
    const data = await custom_feedback(createProject, projectName);
    if(data){
      setProjectOpen(false);
      navigate(`/${data.project_id}/data/data-source/create`);
    }
  };

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const commonStyles = {
    border: '2px solid #ade3d0',
    borderRadius: '4px',
    transition: 'all 0.3s ease',
    '&:hover': {
      borderColor: '#7ac7aa',
    },
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
      <Box
        sx={{
          ...commonStyles,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          minWidth: '160px',
          padding: '8px 5px',
          cursor: 'pointer',
          '&:hover .MuiSvgIcon-root': {
            color: '#7ac7aa',
          },
        }}
        onClick={handleOpenMenu}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <FolderIcon sx={{ color: '#ade3d0', marginRight: '8px', flexShrink: 0, transition: 'color 0.3s ease' }} />
          <Typography sx={{ 
            flexGrow: 1, 
            whiteSpace: 'nowrap', 
            overflow: 'hidden', 
            textOverflow: 'ellipsis',
            color: '#333333'
          }}>
            {projectData.project_name}
          </Typography>
        </Box>
        <ArrowDropDownIcon sx={{ color: '#ade3d0', flexShrink: 0, transition: 'color 0.3s ease' }} />
      </Box>

      <Button
        sx={{
          ...commonStyles,
          minWidth: 'unset',
          padding: '8px',
          color: '#ade3d0',
          '&:hover': {
            ...commonStyles['&:hover'],
            color: '#7ac7aa',
          },
        }}
        onClick={handleClickSetting}
      >
        <SettingsIcon />
      </Button>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        sx={{
          '& .MuiPaper-root': {
            backgroundColor: '#FFFFFF',
            border: '2px solid #ade3d0',
            borderRadius: '4px',
            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
            width: '210px',  
          },
          '& .MuiMenuItem-root': {
            padding: '10px 16px',
          },
        }}
      >
        <MenuItem onClick={handleClickOpen} sx={{ color: '#ade3d0', fontWeight: 'bold' }}>
          <AddIcon sx={{ marginRight: '8px' }} />
          新建项目
        </MenuItem>

        {/* ghost管理员 */}
        {user.user_role === "ghost"  && (<MenuItem value="newProject" onClick={handleClickSearch}>
          <SearchIcon sx={{ marginRight: '8px' }} />
          切换项目
        </MenuItem>)}

        {menuItems.map((item) => (
          <MenuItem
            key={item.project_id}
            onClick={() => {
              handleChange(item);
              handleCloseMenu();
            }}
            selected={urlProjectId === item.project_id}
            sx={{
              '&.Mui-selected': {
                backgroundColor: '#ebf7ef',
              },
              '&:hover': {
                backgroundColor: '#dff0e7',
              },
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {item.project_name}
          </MenuItem>
        ))}
      </Menu>

      <Dialog open={projectOpen} onClose={handleProjectClose} PaperProps={{ sx: { borderRadius: '4px', border: '2px solid #ade3d0' } }}>
        <DialogTitle sx={{ fontSize: '1.3rem', fontWeight: 'bold', color: '#ade3d0', pt: 2 }}>新建项目</DialogTitle>
        <DialogContent>
          <Typography variant="body2" sx={{ color: '#666', mb: 2 }}>
            您可以将同一个团队或业务的数据放到一个项目中。
            每个用户最多允许创建 3 个项目。
          </Typography>
          <TextField
            label="项目名称"
            variant="outlined"
            fullWidth
            margin="normal"
            value={projectName}
            onChange={(e) => setProjectName(e.target.value)}
            sx={{ 
              '& .MuiOutlinedInput-root': { 
                borderRadius: '4px',
                '& fieldset': {
                  borderColor: '#ade3d0',
                },
                '&:hover fieldset': {
                  borderColor: '#9ad1be',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#9ad1be',
                },
              } 
            }}
          />
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <Button onClick={handleProjectClose} variant="outlined" sx={{ borderRadius: '4px', color: '#ade3d0', borderColor: '#ade3d0' }}>取消</Button>
          <Button onClick={handleProjectAdd} variant="contained" sx={{ borderRadius: '4px', backgroundColor: '#ade3d0', color: '#FFFFFF', '&:hover': { backgroundColor: '#9ad1be' } }}>创建</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={searchPOpen} onClose={SearchPClose}>
        <DialogTitle sx={{ padding: '24px' }}>
          <Typography>切换项目</Typography>
        </DialogTitle>
        <DialogContent>
          <TextField
            label="请输入项目 ID"
            variant="outlined"
            fullWidth
            margin="normal"
            value={projectSid}
            onChange={(e) => setProjectSid(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={SearchPClose} variant="outlined">取消</Button>
          <Button onClick={ProjectSeacrh} variant="outlined">切换</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={searchPOpen} onClose={SearchPClose}>
        <DialogTitle sx={{ padding: '24px' }}>
          <Typography>切换项目</Typography>
        </DialogTitle>
        <DialogContent>
          <TextField
            label="请输入项目 ID"
            variant="outlined"
            fullWidth
            margin="normal"
            value={projectSid}
            onChange={(e) => setProjectSid(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={SearchPClose} variant="outlined">取消</Button>
          <Button onClick={ProjectSeacrh} variant="outlined">切换</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ProjectSelector;