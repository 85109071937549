// src/components/FileUpload.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { LinearProgress, Button, Box, Typography } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';
import { getFileUploadParams } from '../api/askTableApi';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const FileUpload = ({ onUploadSuccess }) => {
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (file) {
      handleUpload();
    }
  }, [file]);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    if (!file) return;

    try {
      setUploading(true);

      // 获取上传参数
      const uploadParams = await getFileUploadParams();
      const { bucket, bucket_upload_url, oss_uri_prefix, params, url: oss_endpoint } = uploadParams.oss;
      const fileName = file.name;
      const fileKey = `${oss_uri_prefix}${fileName}`;

      // 准备表单数据
      const formData = new FormData();
      formData.append('OSSAccessKeyId', params.OSSAccessKeyId);
      formData.append('policy', params.policy);
      formData.append('signature', params.signature);
      formData.append('key', fileKey);
      formData.append('file', file);

      // 上传文件到OSS
      const response = await axios.post(bucket_upload_url, formData, {
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setProgress(percentCompleted);
        }
      });

      if (response.status === 204) {
        const fileUrl = `https://${bucket}.${oss_endpoint}/${fileKey}`;
        onUploadSuccess(fileUrl);
      }

      setUploading(false);
      setProgress(0);
    } catch (error) {
      console.error('File upload failed:', error);
      setUploading(false);
    }
  };

  return (
    <Box>
      <Button
        component="label"
        variant="outlined"
        startIcon={<CloudUploadIcon />}
      >
        Upload file
        <VisuallyHiddenInput type="file" onChange={handleFileChange} />
      </Button>
      {uploading && (
        <Box sx={{ width: '100%', mt: 2 }}>
          <LinearProgress variant="determinate" value={progress} />
          <Typography variant="body2" color="textSecondary">{progress}%</Typography>
        </Box>
      )}
    </Box>
  );
};

export default FileUpload;
