import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import {
    Tabs,
    Tab,
    Table,
    Box,
    Typography,
    TableContainer,
    Paper,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TablePagination
} from '@mui/material';
import { getSQLLog, getAnswerLog } from "../api/askTableApi";

const ShowLogs = () => {
    const [tabValue, setTabValue] = useState(0);

    // sql日志的值，页数和每页的行数
    const [sqlLogs, setSQLLogs] = useState([]);
    const [sqlPage, setSQLPage] = useState(0);
    const [sqlRowsPerPage, setSQLRowsPerPage] = useState(5);

    // answer日志的值，页数和每页的行数
    const [answerLogs, setAnswerLogs] = useState([]);
    const [answerPage, setAnswerPage] = useState(0);
    const [answerRowsPerPage, setAnswerRowsPerPage] = useState(5);

    const [error, setError] = useState({ message: '', code: '' });

    // 获取SQL日志
    useEffect(() => {
        const fetchSqlLog = async () => {
            try {
                const response = await getSQLLog(100, 1, 'desc'); // 假设获取100条记录
                setSQLLogs(response.data);
            } catch (error) {
                setError({ message: error.message, code: error.code });
            }
        };

        fetchSqlLog();
    }, []);

    // 获取Answer日志
    useEffect(() => {
        const fetchAnswerLog = async () => {
            try {
                const response = await getAnswerLog(100, 1, 'desc'); // 假设获取100条记录
                setAnswerLogs(response.data);
            } catch (error) {
                setError({ message: error.message, code: error.code });
            }
        };

        fetchAnswerLog();
    }, []);

    // 切换不同内容
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    // SQL日志分页处理，处理用户改变页面
    const handleSQLPageChange = (event, newPage) => {
        setSQLPage(newPage);
    };

    // 处理用户选择每页展示不同的行数时
    const handleSQLRowsPerPageChange = (event) => {
        setSQLRowsPerPage(parseInt(event.target.value, 10));
        setSQLPage(0);
    };

    // 回答日志分页处理
    const handleAnswerPageChange = (event, newPage) => {
        setAnswerPage(newPage);
    };

    const handleAnswerRowsPerPageChange = (event) => {
        setAnswerRowsPerPage(parseInt(event.target.value, 10));
        setAnswerPage(0);
    };

    // 渲染日志单元格内容，处理对象数据
    const renderCellContent = (content) => {
        if (typeof content === 'object' && content !== null) {
            return JSON.stringify(content);  // 将对象转换为字符串
        }
        return content;
    };

    return (
        <Box className="item-page">
            <Typography variant="h5">历史记录</Typography>
            <Box>
                <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        backgroundColor: '#ffffff',
                        '& .MuiTab-root': { color: '#555' },  // 默认颜色
                        '& .Mui-selected': { color: '#000000', fontWeight: 'bold' },  // 选中颜色和加粗
                    }}
                >
                    <Tab label="SQL日志" />
                    <Tab label="回答日志" />
                </Tabs>

                {/* SQL日志表格 */}
                {tabValue === 0 && (
                    <TableContainer component={Paper} sx={{ marginTop: 2, overflow:'auto' }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {['ID', '数据 ID', '角色 ID', '角色参数', '问题', 'SQL', '结果', '用时', '创建时间'].map((header) => (
                                        <TableCell key={header} sx={{ maxWidth: 150, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                            {header}
                                        </TableCell>
                                   ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {sqlLogs.slice(sqlPage * sqlRowsPerPage, sqlPage * sqlRowsPerPage + sqlRowsPerPage).map((log) => (
                                    <TableRow key={log.id}>
                                        <TableCell sx={{ maxWidth: 100, overflow: 'hidden', wordBreak: 'break-word' }}>{log.id}</TableCell>
                                        <TableCell sx={{ maxWidth: 100, overflow: 'hidden', wordBreak: 'break-word' }}>{log.datasource_id}</TableCell>
                                        <TableCell sx={{ maxWidth: 100, overflow: 'hidden', wordBreak: 'break-word' }}>{log.role_id}</TableCell>
                                        <TableCell sx={{ maxWidth: 100, overflow: 'hidden', wordBreak: 'break-word' }}>{renderCellContent(log.role_variables)}</TableCell>
                                        <TableCell sx={{ maxWidth: 100, overflow: 'hidden', wordBreak: 'break-word' }}>{log.question}</TableCell>
                                        <TableCell sx={{ maxWidth: 100, overflow: 'hidden', wordBreak: 'break-word' }}>{log.query?.prepared_statement}</TableCell>
                                        <TableCell sx={{ maxWidth: 100, overflow: 'hidden', wordBreak: 'break-word' }}>{log.status}</TableCell>
                                        <TableCell sx={{ maxWidth: 100, overflow: 'hidden', wordBreak: 'break-word' }}>{log.duration}</TableCell>
                                        <TableCell sx={{ maxWidth: 100, overflow: 'hidden', wordBreak: 'break-word' }}>{log.created_at}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        <TablePagination
                            rowsPerPageOptions={[5]}
                            component="div"
                            count={sqlLogs.length}
                            rowsPerPage={sqlRowsPerPage}
                            page={sqlPage}
                            onPageChange={handleSQLPageChange}
                            onRowsPerPageChange={handleSQLRowsPerPageChange}
                            labelDisplayedRows={({ from, to, count }) => `页码：${sqlPage + 1} / ${Math.ceil(count / sqlRowsPerPage)} `}
                        />
                    </TableContainer>
                )}

                {/* 回答日志表格 */}
                {tabValue === 1 && (
                    <TableContainer component={Paper} sx={{ marginTop: 2,  overflow:'auto'}}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {['ID', '数据 ID', '角色 ID', '角色参数', '最多返回的记录数', '问题', '回答', '结果', '用时', '创建时间'].map((header) => (
                                        <TableCell key={header} sx={{ maxWidth: 150, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                            {header}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {answerLogs.slice(answerPage * answerRowsPerPage, answerPage * answerRowsPerPage + answerRowsPerPage).map((log) => (
                                    <TableRow key={log.id}>
                                        <TableCell sx={{ maxWidth: 60, overflow: 'hidden', wordBreak: 'break-word', whiteSpace: 'normal' }}>{log.id}</TableCell>
                                        <TableCell sx={{ maxWidth: 60, overflow: 'hidden', wordBreak: 'break-word', whiteSpace: 'normal' }}>{log.datasource_id}</TableCell>
                                        <TableCell sx={{ maxWidth: 60, overflow: 'hidden', wordBreak: 'break-word', whiteSpace: 'normal' }}>{log.role_id}</TableCell>
                                        <TableCell sx={{ maxWidth: 60, overflow: 'hidden', wordBreak: 'break-word', whiteSpace: 'normal' }}>{renderCellContent(log.role_variables)}</TableCell>
                                        <TableCell sx={{ maxWidth: 60, overflow: 'hidden', wordBreak: 'break-word', whiteSpace: 'normal' }}>{log.max_rows}</TableCell>
                                        <TableCell sx={{ maxWidth: 60, overflow: 'hidden', wordBreak: 'break-word', whiteSpace: 'normal' }}>{log.question}</TableCell>
                                        <TableCell sx={{ maxWidth: 60, overflow: 'hidden', wordBreak: 'break-word', whiteSpace: 'normal' }}>{renderCellContent(log.answer_text)}</TableCell>
                                        <TableCell sx={{ maxWidth: 60, overflow: 'hidden', wordBreak: 'break-word', whiteSpace: 'normal' }}>{log.status}</TableCell>
                                        <TableCell sx={{ maxWidth: 60, overflow: 'hidden', wordBreak: 'break-word', whiteSpace: 'normal' }}>{log.duration}</TableCell>
                                        <TableCell sx={{ maxWidth: 60, overflow: 'hidden', wordBreak: 'break-word', whiteSpace: 'normal' }}>{log.created_at}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        <TablePagination
                            rowsPerPageOptions={[5]}
                            component="div"
                            count={answerLogs.length}
                            rowsPerPage={answerRowsPerPage}
                            page={answerPage}
                            onPageChange={handleAnswerPageChange}
                            onRowsPerPageChange={handleAnswerRowsPerPageChange}
                            labelDisplayedRows={({ from, to, count }) => `页码：${answerPage + 1} / ${Math.ceil(count / answerRowsPerPage)} `}
                        />
                    </TableContainer>
                )}
            </Box>

        </Box>
    );
};

export default ShowLogs;
