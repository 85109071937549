import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  TextField,
  Button,
  Typography,
  Box,
  Snackbar,
  Alert,
  CircularProgress,
  InputAdornment,
  Fade,
  Link,
} from '@mui/material';
import { styled, keyframes } from '@mui/material/styles';
import {
  Phone as PhoneIcon,
  Person as PersonIcon,
  Sms as SmsIcon,
  Check as CheckIcon,
  ErrorOutline as ErrorOutlineIcon,
} from '@mui/icons-material';
import { sendVerificationCode, bindPhone, checkTokens } from '../api/projectApi';




const pulseAnimation = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(25, 118, 210, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(25, 118, 210, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(25, 118, 210, 0);
  }
`;

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: theme.spacing(2),
    padding: theme.spacing(2),
    boxShadow: '0 8px 32px rgba(0, 0, 0, 0.08)',
    transition: theme.transitions.create(['box-shadow']),
    '&:hover': {
      boxShadow: '0 16px 48px rgba(0, 0, 0, 0.12)',
    },
  },
}));

const Header = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(4),
}));

const IconWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  borderRadius: '50%',
  padding: theme.spacing(1.5),
  marginRight: theme.spacing(2),
  width: theme.spacing(7),
  height: theme.spacing(7),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  animation: `${pulseAnimation} 2s infinite`,
  '& .MuiSvgIcon-root': {
    fontSize: 32,
    color: theme.palette.common.white,
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  '& .MuiOutlinedInput-root': {
    borderRadius: theme.shape.borderRadius,
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:hover': {
      borderColor: theme.palette.primary.main,
    },
    '&.Mui-focused': {
      boxShadow: `0 0 0 2px ${theme.palette.primary.light}`,
    },
  },
}));

const PhoneBinding = ({ open, onClose, onBind, userData }) => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [bindSuccess, setBindSuccess] = useState(false);
  const [showWhitelistError, setShowWhitelistError] = useState(false);
  const [whitelistErrorMessage, setWhitelistErrorMessage] = useState('');
  
  const handleSendCode = async () => {
    if (!phoneNumber) {
      setSnackbar({ open: true, message: '请输入手机号码', severity: 'error' });
      return;
    }
    setLoading(true);
    try {
      const response = await sendVerificationCode(phoneNumber);
      setIsCodeSent(true);
      setSnackbar({ open: true, message: response.message || '验证码已发送', severity: 'success' });
    } catch (error) {
      if (error.code === 1305) {
        setWhitelistErrorMessage('该手机号没有提交内测申请或者尚未通过审核');
        setShowWhitelistError(true);
      }
      console.error('发送验证码失败:', error);
      setSnackbar({ open: true, message: error.message || '发送验证码失败，请重试', severity: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleBind = async () => {
    if (!phoneNumber || !verificationCode) {
      setSnackbar({ open: true, message: '请输入手机号和验证码', severity: 'error' });
      return;
    }
    setLoading(true);
    try {
      await bindPhone(phoneNumber, verificationCode);
      setBindSuccess(true);
      setSnackbar({ open: true, message: '手机号绑定成功', severity: 'success' });
      
      
      setTimeout(() => {
        onBind(phoneNumber);
        onClose();
      }, 2000);
    } catch (error) {
      console.error('Failed to bind phone:', error);
      setSnackbar({ open: true, message: '绑定失败，请检查验证码是否正确', severity: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <>
      <StyledDialog open={open} onClose={onClose} fullWidth maxWidth="xs">
        <DialogContent>
          <Fade in timeout={800}>
            <Box>
              <Header>
                <IconWrapper>
                  <PhoneIcon />
                </IconWrapper>
                <Typography variant="h5" component="h2">
                  绑定手机号
                </Typography>
              </Header>
              {userData && (
                <Box mb={3}>
                  <Typography variant="subtitle1" gutterBottom>
                    <PersonIcon
                      fontSize="small"
                      sx={{ verticalAlign: 'middle', mr: 1, color: 'primary.main' }}
                    />
                    欢迎，{userData.name}
                  </Typography>
                </Box>
              )}
              <StyledTextField
                type="tel"
                fullWidth
                variant="outlined"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PhoneIcon color="action" />
                    </InputAdornment>
                  ),
                }}
              />
              <Box display="flex" mb={2}>
                <StyledTextField
                  type="text"
                  fullWidth
                  variant="outlined"
                  value={verificationCode}
                  onChange={(e) => setVerificationCode(e.target.value)}
                  sx={{ mr: 1 }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SmsIcon color="action" />
                      </InputAdornment>
                    ),
                  }}
                />
                <Button
                  variant="outlined"
                  onClick={handleSendCode}
                  disabled={isCodeSent || loading}
                  sx={{ minWidth: '130px', maxHeight: '55px', borderRadius: '5px' }}
                >
                  {loading ? <CircularProgress size={24} /> : isCodeSent ? '已发送' : '发送验证码'}
                </Button>
              </Box>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={handleBind}
                disabled={loading || bindSuccess}
                sx={{ borderRadius: '5px' }}
              >
                {loading ? (
                  <CircularProgress size={24} />
                ) : bindSuccess ? (
                  <>
                    <CheckIcon sx={{ mr: 1 }} /> 绑定成功
                  </>
                ) : (
                  '绑定手机号'
                )}
              </Button>
            </Box>
          </Fade>
        </DialogContent>
      </StyledDialog>
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%', boxShadow: 3 }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
      <Dialog
        open={showWhitelistError}
        onClose={() => setShowWhitelistError(false)}
        PaperProps={{
          sx: {
            borderRadius: '12px',
            padding: '24px',
            maxWidth: '400px',
          }
        }}
      >
        <DialogContent sx={{ padding: 0 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <ErrorOutlineIcon sx={{ fontSize: 60, color: 'error.main', mb: 2 }} />
            <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', textAlign: 'center' }}>
              抱歉，该手机号没有提交内测申请或者尚未通过审核
            </Typography>

            <Box sx={{ bgcolor: 'grey.100', p: 2, borderRadius: '8px', mb: 3, width: '100%' }}>
              <Typography variant="body2" sx={{ textAlign: 'center' }}>
                <Link 
                  href="https://datamini.feishu.cn/share/base/form/shrcnGVtAl5Zx5StlsTrLVieJth" 
                  color="primary" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  sx={{ 
                    fontWeight: 'bold', 
                    textDecoration: 'none', 
                    '&:hover': { textDecoration: 'underline' },
                    fontSize: '1.1rem' // 增加字体大小
                  }}
                >
                  点击此处申请 AskTable 内测
                </Link>
              </Typography>
            </Box>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => setShowWhitelistError(false)}
              sx={{ 
                mt: 2, 
                borderRadius: '8px', 
                py: 1.5,
                fontWeight: 'bold',
                '&:hover': {
                  backgroundColor: 'primary.dark',
                }
              }}
            >
              我知道了
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PhoneBinding;