import React, { useState, useRef, useEffect } from 'react';
import { createDatasource, getSecureTunnels, createSecureTunnel } from '../api/askTableApi';
import { 
  Button, TextField, FormControl, InputLabel, Select, MenuItem, Snackbar, SnackbarContent, 
  Box, Typography, FormHelperText, RadioGroup, FormControlLabel, Radio, Stepper, Step, 
  StepLabel, Card, Grid, Paper
} from '@mui/material';
import FileUpload from '../components/FileUpload';
import './CreateDatasource.css';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { ReactComponent as MysqlIcon } from '../assets/files/mysqlIcon.svg';
import { ReactComponent as TidbIcon } from '../assets/files/tidbIcon.svg';
import { ReactComponent as PostgresqlIcon } from '../assets/files/postgresqlIcon.svg';
import { ReactComponent as OceanBaseIcon } from '../assets/files/oceanbaseIcon.svg';
import { ReactComponent as ExcelIcon } from '../assets/files/excelIcon.svg';
import { ReactComponent as CSVIcon } from '../assets/files/csvIcon.svg';
import { ReactComponent as ClickHouse } from '../assets/files/clickHouse.svg';
import { Description, Storage } from '@mui/icons-material';

const steps = ['选择类型', '添加数据'];

const componentMap = {
  mysql: { icon: MysqlIcon, label: '<strong style="font-size: 1.1em;">MySQL</strong><br/>5.7 / 8.0' },
  tidb: { icon: TidbIcon, label: '<strong style="font-size: 1.1em;">TiDB</strong><br/>8.1.0' },
  postgresql: { icon: PostgresqlIcon, label: '<strong style="font-size: 1.1em;">PostgreSQL</strong><br/>15' },
  oceanbase: { icon: OceanBaseIcon, label: '<strong style="font-size: 1.1em;">Oceanbase</strong><br/>4.2.2' },
  clickhouse: { icon: ClickHouse, label: '<strong style="font-size: 1.1em;">ClickHouse</strong><br/>22.12' },
  excel: { icon: ExcelIcon, label: '<strong style="font-size: 1.1em;">Excel</strong><br/>.xlsx /  .xls' },
  csv: { icon: CSVIcon, label: '<strong style="font-size: 1.1em;">CSV</strong><br/>.csv' }
};

const GreenOutlinedBox = styled(Paper)(({ theme }) => ({
  border: '2px solid #005E3C',
  borderRadius: theme.shape.borderRadius,
  boxShadow: 'none',
  backgroundColor: 'transparent',
  padding: theme.spacing(2),
}));

const CustomCard = styled(Card)(({ theme, selected }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(1),
  backgroundColor: selected ? '#005E3C' : 'transparent',//文字样式
  color: selected ? 'white' : 'inherit',
  borderRadius: theme.shape.borderRadius,
  boxShadow: selected ? '0 4px 8px rgba(0,0,0,0.2)' : 'none',
  cursor: 'pointer',
  transition: 'all 0.3s ease',
  '&:hover': {
    backgroundColor: selected ? '#005E3C' : '#e0e0e0',
    transform: 'translateY(-2px)',
  },
  '& .engine-icon-overlay': {
    width: 50,
    height: 50,
    marginBottom: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    filter: selected ? 'invert(100%)' : 'none',
  }
}));

const CardContent = ({ type }) => {
  const { icon: Icon, label } = componentMap[type];

  return (
    <>
      <Icon className="engine-icon-overlay" />
      <Typography
        variant="body2"
        dangerouslySetInnerHTML={{ __html: label }}
        sx={{ mt: 1, textAlign: 'center' }}
      />
    </>
  );
};

const CreateDatasource = () => {
  const [selection, setSelection] = useState('');
  const [uploadOption, setUploadOption] = useState('upload');
  const [accessConfig, setAccessConfig] = useState({ 
    host: '', port: '3306', user: '', password: '', location_url: '', database: '', securetunnel_id: '' 
  });
  const [name, setName] = useState('');
  const [error, setError] = useState({ message: '', code: '' });
  const [fileUploaded, setFileUploaded] = useState(false);
  const [secureTunnels, setSecureTunnels] = useState([]);
  const fileInputRef = useRef(null);
  const [activeStep, setActiveStep] = useState(0);
  const [tunnelName, setTunnelName] = useState('');
  const navigate = useNavigate();
  const [selected, setSelected] = useState('');
  const [secureOpen, setSecureOpen] = useState(false);
  
  const SelectedComponent = componentMap[selected]?.icon || MysqlIcon;

  useEffect(() => {
    if (uploadOption === 'upload' && fileInputRef.current) {
      fileInputRef.current.click();
    }
  }, [uploadOption]);

  const fetchSecureTunnels = async () => {
    try {
      const data = await getSecureTunnels(10, 1, 'desc');
      setSecureTunnels(data.data);
    } catch (error) {
      console.error('Failed to fetch secure tunnels:', error);
      setError({ message: error.message, code: error.code });
    }
  };

  useEffect(() => {
    fetchSecureTunnels();
  }, []);

  useEffect(() => {
    if (['mysql', 'tidb', 'postgresql', 'oceanbase', 'clickhouse'].includes(selected)) {
      fetchSecureTunnels();
    }
  }, [selected]);

  // const isPrivateIP = (ip) => {
  //   const parts = ip.split('.').map(Number);
  //   if (parts[0] === 10 || 
  //       (parts[0] === 172 && parts[1] >= 16 && parts[1] <= 31) || 
  //       (parts[0] === 192 && parts[1] === 168)) {
  //     setSecureOpen(true);
  //   } else {
  //     setSecureOpen(false);
  //   }
  // }
  const isPrivateIP = (hostname) => {
    // 检查是否为内网域名
    if (hostname.endsWith('.local') || hostname.endsWith('.lan') || hostname === 'localhost') {
      setSecureOpen(true);
      return true;
    }
  
    // 检查是否为内网IP地址
    if (hostname.includes(':')) {  // IPv6
      if( hostname.startsWith('fc') || hostname.startsWith('fd')){
        setSecureOpen(true);
        return;
      }
    } else {  // IPv4
      const parts = hostname.split('.').map(Number);
      if( parts[0] === 10 || 
          (parts[0] === 172 && parts[1] >= 16 && parts[1] <= 31) || 
          (parts[0] === 192 && parts[1] === 168) || 
          hostname === '127.0.0.1')
      {
        setSecureOpen(true);
        return;
      }
    }
    setSecureOpen(false);
    return;
  };

  const handleFileUploadSuccess = (fileUrl) => {
    setAccessConfig((prevConfig) => ({ ...prevConfig, location_url: fileUrl }));
    setFileUploaded(true);
  };

  
  const handleCreateDatasource = async () => {
    const newAccessConfig = ['mysql', 'tidb', 'postgresql', 'oceanbase', 'clickhouse'].includes(selected)
      ? { host: accessConfig.host, port: accessConfig.port, user: accessConfig.user, password: accessConfig.password, database: accessConfig.database, securetunnel_id: accessConfig.securetunnel_id }
      : { location_type: 'http', location_url: accessConfig.location_url };

    try {
      await createDatasource({ engine: selected, access_config: newAccessConfig, name });
      navigate('/data/data-source');
    } catch (error) {
      console.error('Failed to create datasource:', error);
      setError({ message: error.message, code: error.code });
    }
  };

  const handleCloseSnackbar = () => {
    setError({ message: '', code: '' });
  };

  const handleNext = () => {
    if (activeStep === 0 && !selected) {
      setError({ message: '选择数据类型不能为空！', code: 'value is NULL' });
      return;
    }
    if (activeStep === 0 && selection === 'none') {
      setActiveStep(steps.length - 2);
    }
    if (activeStep === 1 && !accessConfig.securetunnel_id) {
      setError({ message: '安全隧道不能为空！', code: 'value is NULL' });
      return;
    }
    // 设置端口的初始值
    setAccessConfig((prevConfig) => ({ ...prevConfig, port: getDefaultPort(selected) }));
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleCreateSecureTunnel = async () => {
    try {
      const newTunnel = await createSecureTunnel({ name: tunnelName });
      setTunnelName('');
      const data = await getSecureTunnels(20, 1, 'desc');
      setAccessConfig(prevConfig => ({ ...prevConfig, securetunnel_id: newTunnel.id }));
      setSecureTunnels(data.data);
    } catch (error) {
      console.error('Failed to create secure tunnel:', error);
      setError({ message: error.message, code: error.code });
    }
  };

  const getDefaultPort = (selected) => {
    const portMap = {
      mysql: 3306,
      tidb: 4000,
      postgresql: 5432,
      oceanbase: 3306,
      clickhouse: 8123,
    };
    return portMap[selected] || 3306;
  };

  const handleSelect = (type) => {
    setSelected(type);
  };

  return (
    <Box className="item-page">
      <Typography>添加数据</Typography>
      <Stepper activeStep={activeStep} alternativeLabel sx={{ mb: 4, mt: 7 }}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Box sx={{ mt: 4 }}>
        {activeStep === 0 && (
          <GreenOutlinedBox>
            <Grid container>
              <Grid item xs={6} sx={{ borderRight: '1px solid #005E3C' }}>
                <Box sx={{ p: 2 }}>
                  <Typography variant="h6" sx={{ mb: 1, paddingLeft: '20px', fontWeight: 'bold', display: 'flex', alignItems: 'center', color: '#006A4C' }}>
                    <Box sx={{ color: '#006A4C', marginRight: '8px', display: 'flex', alignItems: 'center' }}>
                      <Description />
                    </Box>
                    文件
                  </Typography>
                  <Grid container spacing={2} sx={{ justifyContent: 'center', mt: 8 }}>
                    {['excel', 'csv'].map((type) => (
                      <Grid item xs={4} key={type}>
                        <CustomCard selected={selected === type} onClick={() => handleSelect(type)}>
                          <CardContent type={type} />
                        </CustomCard>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box sx={{ p: 2 }}>
                  <Typography variant="h6" sx={{ mb: 1, paddingLeft: '20px', fontWeight: 'bold', display: 'flex', alignItems: 'center', color: '#006A4C' }}>
                    <Box sx={{ color: '#006A4C', marginRight: '8px', display: 'flex', alignItems: 'center' }}>
                      <Storage />
                    </Box>
                    数据库
                  </Typography>
                  <Grid container spacing={2} sx={{ justifyContent: 'center', mt: 2 }}>
                    {['mysql', 'postgresql', 'tidb', 'oceanbase', 'clickhouse'].map((type) => (
                      <Grid item xs={4} key={type}>
                        <CustomCard selected={selected === type} onClick={() => handleSelect(type)}>
                          <CardContent type={type} />
                        </CustomCard>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </GreenOutlinedBox>
        )}
        {activeStep === 1 && (
          <GreenOutlinedBox>
            <Box sx={{margin: '0 auto', p: 0}}>
              <Grid container spacing={4}>
                <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Box sx={{ transform: 'scale(5)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <SelectedComponent style={{ color: 'gray', opacity: 0.5 }} />
                  </Box>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    label="给这个数据源起个名字"
                    fullWidth
                    margin="normal"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    sx={{ mb: 3 }}
                  />
                  {selected && (
                    <>
                      {['mysql', 'tidb', 'postgresql', 'oceanbase', 'clickhouse'].includes(selected) ? (
                        <>
                          <Box sx={{ display: 'flex', mb: 2 }}>
                            <TextField
                              label="主机"
                              fullWidth
                              value={accessConfig.host}
                              onChange={(e) => {
                                setAccessConfig({ ...accessConfig, host: e.target.value });
                                isPrivateIP(e.target.value);
                              }}
                              helperText={`请填写具体地址，不要使用 localhost 或 127.0.0.1。${selected === 'clickhouse' ? 'HTTP 协议端口' : ''}`}
                              sx={{ mr: 2 }}
                            />
                            <TextField
                              label="端口"
                              fullWidth
                              type="number"
                              value={accessConfig.port}
                              onChange={(e) => setAccessConfig({ ...accessConfig, port: parseInt(e.target.value, 10) || getDefaultPort(selected)  })}
                            />
                          </Box>
                          {secureOpen && (
                            <FormControl fullWidth sx={{ mb: 2 }}>
                              <InputLabel id="securetunnel-label">用已有的安全隧道</InputLabel>
                              <Select
                                labelId="securetunnel-label"
                                value={accessConfig.securetunnel_id || "none"}
                                fullWidth
                                onChange={(e) => setAccessConfig({ ...accessConfig, securetunnel_id: e.target.value === "none" ? "" : e.target.value })}
                                label="用已有的安全隧道"
                                MenuProps={{
                                  PaperProps: {
                                    sx: {
                                      maxHeight: 300,
                                      width: 250
                                    }
                                  }
                                }}
                              >
                                <MenuItem value="none">请选择其中一个</MenuItem>
                                {secureTunnels.map((st) => (
                                  <MenuItem key={st.id} value={st.id}>
                                    {st.name ? `${st.name} (${st.id})` : `未命名隧道 (${st.id})`}
                                  </MenuItem>
                                ))}
                              </Select>
                              <Box sx={{  pt:2,borderTop: '1px solid', borderColor: 'divider', mt: 2 ,display: 'flex', }}>
                                <TextField
                                  label="新建安全隧道"
                                  fullWidth
                                  value={tunnelName}
                                  onChange={(e) => setTunnelName(e.target.value)}
                                  helperText="创建新安全隧道后，您需要在内网主机执行相应命令"
                                />
                                <Button variant="outlined" color="primary"  onClick={handleCreateSecureTunnel}
                                  sx={{
                                    width: 80,  // 设置按钮的宽度
                                    height: 55,  // 设置按钮的高度
                                    padding: '10px 20px',  // 设置按钮的内间距
                                    fontSize: '1rem',  // 设置按钮的字体大小
                                    borderRadius: '8px',  // 设置按钮的圆角
                                    marginLeft:"10px"
                                  }}
                                >
                                  创建
                                </Button>
                              </Box>
                            </FormControl>
                          )}
                          <Box sx={{ display: 'flex', mb: 3 }}>
                            <TextField
                              label="用户名"
                              fullWidth
                              value={accessConfig.user}
                              onChange={(e) => setAccessConfig({ ...accessConfig, user: e.target.value })}
                              sx={{ mr: 2 }}
                            />
                            <TextField
                              label="密码"
                              fullWidth
                              type="password"
                              value={accessConfig.password}
                              onChange={(e) => setAccessConfig({ ...accessConfig, password: e.target.value })}
                            />
                          </Box>
                          <TextField
                            label="数据库DB"
                            fullWidth
                            value={accessConfig.database}
                            onChange={(e) => setAccessConfig({ ...accessConfig, database: e.target.value })}
                            helperText={selected === 'postgresql' ? '请输入一个数据库' : '可以输入0-N个数据库，多个用英文逗号分隔，若为空表示全部数据库'}
                            sx={{ mb: 3 }}
                          />
                        </>
                      ) : (
                        <>
                          <FormControl component="fieldset" fullWidth margin="normal">
                            <RadioGroup
                              value={uploadOption}
                              onChange={(e) => {
                                setUploadOption(e.target.value);
                                setFileUploaded(false);
                              }}
                            >
                              <FormControlLabel value="upload" control={<Radio />} label="上传文件" />
                              <FormControlLabel value="url" control={<Radio />} label="直接输入URL" />
                              
                            </RadioGroup>
                          </FormControl>
                          {uploadOption === 'url' ? (
                            <TextField
                              label="文件下载URL"
                              fullWidth
                              margin="normal"
                              value={accessConfig.location_url}
                              onChange={(e) => setAccessConfig({ ...accessConfig, location_url: e.target.value })}
                              sx={{ mb: 3 }}
                            />
                          ) : (
                            <FileUpload onUploadSuccess={handleFileUploadSuccess} ref={fileInputRef} sx={{ mb: 3 }} />
                          )}
                          {fileUploaded && (
                            <Typography variant="body2" color="success" sx={{ mt: 1 }}>
                              文件上传成功
                            </Typography>
                          )}
                        </>
                      )}
                    </>
                  )}
                </Grid>
              </Grid>
            </Box>
          </GreenOutlinedBox>
        )}
      </Box>
      <Box display="flex" justifyContent="space-between" sx={{ mt: 4 }}>
        <Button 
          variant="outlined" 
          onClick={handleBack} 
          disabled={activeStep === 0}
        >
          上一步
        </Button>
        <Box>
          {/* {activeStep === steps.length - 1 && (
            <Button 
              variant="outlined" 
              onClick={jumpChat} 
              sx={{ mr: 2 }}
            >
              开始聊天
            </Button>
          )} */}
          <Button 
            variant="outlined" 
            onClick={activeStep === steps.length - 1 ? handleCreateDatasource : handleNext}
          >
            {activeStep === steps.length - 1 ? '立即添加' : '下一步'}
          </Button>
        </Box>
      </Box>
      <Snackbar
        open={!!error.message}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <SnackbarContent
          message={
            <span>
              <strong>Error {error.code}:</strong> {error.message}
            </span>
          }
        />
      </Snackbar>
    </Box>
  );
};

export default CreateDatasource;