import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Typography, Link } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import loginwxImage from '../assets/loginwx.png';
import { loginUser } from '../api/projectApi';
import PhoneBinding from './PhoneBinding'; // 确保导入了 PhoneBinding 组件

const LoginPhone = () => {
  const [showPhoneBinding, setShowPhoneBinding] = useState(false);
  const [userData, setUserData] = useState(null);
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js';
    script.async = true;
    document.body.appendChild(script);
  
    script.onload = () => {
      const loginContainer = document.getElementById('login_container');
      if (window.WxLogin && typeof window.WxLogin === 'function' && loginContainer) {
        var obj = new window.WxLogin({
          self_redirect: false,
          id: "login_container",
          appid: "wx1f4d7a11cf77ef36",
          scope: "snsapi_login",
          redirect_uri: encodeURIComponent("https://cloud.asktable.com/login"),
          state: "STATE",
          style: "black",
          href: "data:text/css;base64,LmltcG93ZXJCb3ggLnFyY29kZSB7d2lkdGg6IDIwMHB4O30NCi5pbXBvd2VyQm94IC50aXRsZSB7ZGlzcGxheTogbm9uZTt9DQouaW1wb3dlckJveCAuaW5mbyB7ZGlzcGxheTogbm9uZTt9DQouaW1wb3dlckJveCAud3JwX2NvZGUge21hcmdpbi10b3A6IDA7fQ=="
        });
      } else {
        console.error('WxLogin is not available');
      }
    };
    // TODO 其实这样的逻辑感觉放到另一个页面比较好, 这样才能有反馈, 确实是在登录了
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get('code');
    if (code) {
      handleCodeParameter(code);
    }
  }, []);

  const handleCodeParameter = async (code) => {
      const data = await loginUser(code);
      setUserData(data);
      if (data.is_bind_phone) {
        localStorage.setItem('at_token', data.user_token);
        handleLogin(data.project_id);
      } else {
        // Phone is not bound, setting SMS tokens 强制要求绑定手机号,所有先存到sms
        localStorage.setItem('at_smstoken', data.user_token);
        localStorage.setItem('at_smspid', data.project_id);
        setShowPhoneBinding(true);
      }
  };
  
  // 在 handleBindSuccess 函数中添加 token 检查
  const handleBindSuccess = () => {
    const projectId1 = localStorage.getItem('at_smspid');
    setShowPhoneBinding(false);
    handleLogin(projectId1);
  }

  const handleLogin = (project_id) => {
    navigate(`/${project_id}/talk/chat`);
  };

  return (
    <Box sx={{
      backgroundImage: `url(${loginwxImage})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      width: '100vw',
      height: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}>
      <Box sx={{
        width: '500px',
        padding: '40px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        borderRadius: 4,
        boxShadow: '0 8px 32px rgba(0, 0, 0, 0.1)'
      }}>
        <Typography variant="h4" gutterBottom sx={{ color: theme.palette.primary.main, fontWeight: 'bold', mb: 3 }}>
          欢迎使用 AskTable
        </Typography>
        <Typography variant="body2" gutterBottom sx={{ color: theme.palette.text.primary, mb: 2, textAlign: 'center' }}>
          立即使用 微信 扫码注册/登录
        </Typography>
        <Box id='login_container' sx={{ mb: 2, '& .impowerBox .qrcode': { width: '200px !important', height: '200px !important' } }} />
        <Box sx={{ bgcolor: 'grey.100', p: 2, borderRadius: '8px', mb: 3, width: '100%' ,mt:-22}}>
              <Typography variant="body2" sx={{ textAlign: 'center' }}>
                <Link 
                  href="https://datamini.feishu.cn/share/base/form/shrcnGVtAl5Zx5StlsTrLVieJth" 
                  color="primary" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  sx={{ 
                    fontWeight: 'bold', 
                    textDecoration: 'none', 
                    '&:hover': { textDecoration: 'underline' },
                    fontSize: '1.1rem' // 增加字体大小
                  }}
                >
                  点击此处申请内测
                </Link>
              </Typography>
              <Typography variant="body2" gutterBottom sx={{ color: theme.palette.text.primary, mb: 2, textAlign: 'center' }}>
              通过审核后，请使用申请时填写的手机号登录
              </Typography>
          </Box>
        <Typography variant="body2" sx={{color: theme.palette.text.primary, textAlign: 'center' }}>
          扫码注册或登录即表示同意
          <Link href="https://docs.asktable.com/docs/appendix/terms-of-service" color="primary" sx={{ fontWeight: 'bold', mx: 0.5 }}>
            《用户协议》
          </Link>
          和
          <Link href="https://docs.asktable.com/docs/appendix/privacy-policy" color="primary" sx={{ fontWeight: 'bold', mx: 0.5 }}>
            《隐私政策》
          </Link>
        </Typography>
      </Box>
      {showPhoneBinding && (
        <PhoneBinding
          open={showPhoneBinding}
          onClose={() => setShowPhoneBinding(false)}
          onBind={handleBindSuccess}
          userData={userData}
        />
      )}
    </Box>
  );
};

export default LoginPhone;